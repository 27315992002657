import { Form, Row, Col, Alert, Card, Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setIsLoading } from './general/actions';
import React, { useEffect, useState } from 'react';
import OrderSummary from './order/summary';
import axiosInstance from './general/axiosInstance';
import { Link } from 'react-router-dom';

import AgreementList from './config/agreementList';

function Main(props) {

    const { organization_id, setIsLoading, roles, userId } = props;

    const [orders, setOrders] = useState([]);

    const [hasEnableNotifications, setHasEnableNotifications] = useState(Notification.permission === 'granted');

    const [countPendingOrders, setCountPendingOrders] = useState(0);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    const [nextDaysFilter, setNextDaysFilter] = useState(parseInt(localStorage.nextDaysFilter) || 8);

    const getCountPendingOrders = async (noShowLoading) => {
        if (!noShowLoading) {
            setIsLoading(true);
        }
        try {
            const response = await axiosInstance.get('/orders/pending-count/');
            setCountPendingOrders(response.data);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const getOrders = async () => {
        if (!organization_id) {
            return;
        }
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/orders/next-delivery/'+ nextDaysFilter);
            console.log(response.data);

            setOrders(response.data);

            if (response.data.length) {
                setMessage('');
            } else {
                setMessageType('info');
                setMessage('No tiene pedidos para entrega en los proximos 8 días');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const enableNotifications = () => {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission();
        }
        setHasEnableNotifications(true);
    }


    useEffect(() => {
        localStorage.nextDaysFilter = nextDaysFilter || '8';
        getOrders();
    }, [nextDaysFilter]);

    // initialize
    useEffect(() => {
        if (!organization_id || !roles.length) {
            let msg = '¡Bienvenido! ';
            
            if (!organization_id) {
                msg += 'Tu usuario aún no está asociado a ninguna entidad. ';
            } else if (!roles.length) {
                msg += 'Tu usuario esta pendiente de asignación de roles. ';
            }

            msg += 'Te recomendamos que te pongas en contacto con el administrador de tu entidad para resolver esta situación.';

            setMessage(msg);
            setMessageType('danger');
        }
        setNextDaysFilter(parseInt(localStorage.nextDaysFilter) || nextDaysFilter);
        getCountPendingOrders();

        const intervalId = setInterval(() => {
            getCountPendingOrders(true);
        }, 180000); // 1 minutes = 60000

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
        {organization_id == 1000 && (userId == 10 || userId == 22) ? 
            <Card as={Container}>
                <Alert variant={'danger'}>
                <b>
                    ¡Atención!{' '}
                    </b>

No hemos recibido el pago correspondiente a tu plan Pyme. 
                </Alert>
                <Card.Footer align='start'> 
                    <spam>

Es importante que realices el pago de manera inmediata para evitar interrupciones en tu servicio. Paga ahora desde Mercado pago {' '}
                    </spam>
                    <Button  variant={'success'} href='http://mpago.li/2K6u4RF' target='blank' >
                        Pagar ahora
                    </Button>
                </Card.Footer>
            </Card>
            :'' }
            <br></br>
            {!hasEnableNotifications ?
                <Card as={Container}>
                    <Alert variant={'info'}>
                        Te recomendamos habilitar las notificaciónes de Abi
                    </Alert>
                    <Card.Footer align='end'>
                        <Button onClick={enableNotifications}>
                            Habilitar Notificaciónes
                        </Button>
                    </Card.Footer>
                </Card>
                : ''}
            <br></br>
            {(roles.includes('provider_approver') || roles.includes('customer_approver')) ?
                <Card as={Container}>
                    <Card.Title>Mis tareas:</Card.Title>
                    <Alert variant={'warning'}>
                        {countPendingOrders ? <>
                            Tienes {countPendingOrders} pedido{countPendingOrders > 1 ? 's' : ''} pendiente{countPendingOrders > 1 ? 's' : ''} por aprobar
                        </>
                            : '¡Estas al día!'
                        }
                    </Alert>
                    {countPendingOrders ?
                        <Card.Footer align='end'>
                            <Button as={Link} to="/order/approve">
                                Ir a aprobar pedidos
                            </Button>
                        </Card.Footer>
                        : ''}
                </Card>
                : ''}
            <br></br>
            <Card as={Container}>
                <Container>
                    {orders.length ?
                        <Alert variant={'info'}>
                            Aquí tienes los pedidos con fecha de entrega entre hoy y los próximos {nextDaysFilter} días
                        </Alert>
                        : ''}
                    {message &&
                        <Alert variant={messageType || 'info'}>
                            {message}
                        </Alert>
                    }

                    <Form.Group as={Row} >
                        <Form.Label as={Col}  md={2} xs={6}>Cambiar dias a filtrar:</Form.Label>
                        <Col  md={2} xs={6}>
                            <Form.Control
                                type="number"
                                value={nextDaysFilter}
                                onChange={(e) => setNextDaysFilter(e.target.value)}
                                min={1}
                            />
                        </Col>
                    </Form.Group>
                </Container >
                <OrderSummary getOrders={getOrders} orders={orders} />
            </Card>
            <br></br>
            <Card as={Container}>
                <Alert variant={'info'}>
                    Este es el listado de contratos de tu entidad en Abi
                </Alert>
                <AgreementList showProductsBtn={true} />
            </Card>
        </>
    );
}

function mapStateToProps(state) {
    return {
        organization_id: state.organization_id,
        userId: state.userId,
        username: state.username,
        isLoggedIn: state.isLoggedIn,
        roles: state.roles,
    };
}
const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);