import { HOST } from '../general/utils';

function Help() {

    return (
        <div className='container'>
          <br></br>
          <h5>¡Bienvenido a la página de ayuda de Abi!</h5>
          <br></br>
          <div>Estamos aquí para brindarte toda la asistencia que necesitas para aprovechar al máximo nuestro sistema Abi. Ya sea que seas un usuario nuevo que busca orientación para comenzar o un experto que busca respuestas a preguntas específicas, nuestra página de ayuda está diseñada para servirte.</div>
          <br></br>
          <div>Aquí encontrarás tutoriales detallados, guías paso a paso y respuestas a las preguntas más frecuentes. Nuestra misión es proporcionarte la información que necesitas de manera clara y concisa, para que puedas optimizar tu experiencia con Abi y aprovechar todas sus funcionalidades.</div>
          <br></br>
          <div>No importa si eres un administrador, un usuario final o un miembro de nuestro equipo de soporte; estamos comprometidos en brindarte la mejor asistencia posible. Si alguna vez te sientes perdido o necesitas ayuda adicional, no dudes en ponerte en contacto con nuestro equipo de soporte, que estará encantado de ayudarte.</div>
          <br></br>
          <div>Gracias por elegir Abi. Estamos emocionados de acompañarte en tu viaje con nuestro sistema y esperamos que esta página de ayuda sea una valiosa fuente de información para ti.</div>
          <br></br>
          <div>
            <ul>
              <li>
                <h4>
                  ¿Qué es Abi?
                </h4>
                <span>
                Abi es una aplicación web diseñada para gestionar pedidos de suministros ligados a un presupuesto. Facilita el proceso de seguimiento de cada pedido, generación de reportes y control de la ejecución presupuestal.
                </span>
              </li>
              <li>
                <h4>
                 ¿Qué ventajas ofrece Abi?
                </h4>
                <span>
                  Abi permite un control detallado de los centros de costos, fondos y usuarios. Facilita la generación de reportes personalizados y simplifica el proceso de gestión de pedidos.
                </span>
              </li>
              <li>
                <h4>
                  ¿Abi es compatible con dispositivos móviles?
                </h4>
                <span>
                  Sí, Abi utiliza diseño web responsivo y es compatible con dispositivos móviles, lo que significa que puedes acceder a él desde cualquier dispositivo, incluyendo equipos móviles.
                  </span>
              </li>
              <li>
                <h4>
                  ¿Cómo puedo agregar un nuevo usuario?
                </h4>
                <span>
                Para agregar un nuevo usuario, este debe registrarse en la plataforma y luego el administrador debe asignarle los permisos y fondos correspondientes. Esto se puede hacer a través de la sección de configuración de usuarios.
                </span>
              </li>
              <li>
                <h4>
                  ¿Cómo se asignan los fondos a los usuarios?
                </h4>
                <span>
                  Los fondos se asignan a los usuarios a través de la sección de configuración de fondos. Debes especificar a qué usuario o centro de costos se asignarán los fondos y la cantidad correspondiente.
                </span>
              </li>
              <li>
                <h4>
                  ¿Qué tipo de reportes puedo generar en Abi?
                </h4>
                <span>
                Abi permite generar una variedad de reportes, incluyendo informes de ejecución presupuestal por contrato, distribución por centros de costos, seguimiento de pedidos y más. Los reportes se pueden descargar en formatos como Excel.
                </span>
              </li>
            </ul>

            <h4>Instructivos</h4>
            <ul>
            <li>
                <a href={HOST + 'storage/files/createOrder.pdf'} target='blank'>
                  ¿Como crear un pedido?
                </a>
              </li>
              <li>
                <a href={HOST + 'storage/files/addBudgetToUser.pdf'} target='blank'>
                  ¿Como agregar presupuesto a un usuario nuevo?
                </a>
              </li>
            </ul>
            
          </div>
        </div>
    );
};

export default Help;