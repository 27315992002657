function login(user) {
  return {
    type: 'LOGIN',
    payload: user
  };
}

function logout() {
  return {
    type: 'LOGOUT'
  };
}

function setIsLoading(isLoading) {
  return {
    type: 'LOADING',
    payload: isLoading
  };
}

export { login, logout,  setIsLoading };