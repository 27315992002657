
import { Form, Button, Row, Col, Table, Alert, Collapse, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setIsLoading } from '../general/actions';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../general/axiosInstance';

import AgreementList from './agreementList';

function Organization(props) {
    const { setIsLoading } = props;

    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const [agreements, setAgreements] = useState([]);
    ;
    const [organization, setOrganization] = useState({
        name: '',
        phone: '',
        ext_id: '',
        address: '',
    });

    const getOrganization = () => {
        if (organization.name) {
            return;
        }
        setIsLoading(true);
        axiosInstance.get('/organizations/current')
            .then(response => {

                setOrganization(response.data);
                console.log(response.data);

            }).catch(error => {
                setMessageType('danger');
                setMessage(error.response.data.message);
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const update = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axiosInstance.put('/organizations/' + organization.id, organization);

            setMessageType('success');
            setMessage('Información actualizada');
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            setMessage(error.response.data.message);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    // init
    React.useEffect(() => {
        setMessage('');
        if (!organization.name) {
            getOrganization();
        }
    }, []);

    return (<>
        <Row>
            <Col md={9} xs={12}> <legend>Entidad {organization.id}: {organization.name}</legend></Col>
        </Row>
        <hr />
        <br />

        <Form onSubmit={update}>
            <Row>
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nit</Form.Label>
                        <Form.Control type="text" value={organization.ext_id} required
                            onChange={(e) => setOrganization({ ...organization, ext_id: e.target.value })}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Entidad</Form.Label>
                        <Form.Control type="text" value={organization.name} required
                            onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="text" value={organization.phone} required
                            onChange={(e) => setOrganization({ ...organization, phone: e.target.value })}
                        />
                    </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" value={organization.address} required
                            onChange={(e) => setOrganization({ ...organization, address: e.target.value })}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={8} xs={12} align={'end'}>
                    <Button variant="dark" type='submit'>
                        Guardar
                    </Button>
                </Col>
            </Row>
        </Form>
        <br></br>
        <Row>
            <Col md={8} xs={12}>

                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
            </Col>
        </Row>

        <AgreementList></AgreementList>

    </>);

}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Organization);