import { Button, Row, Col, Card, OverlayTrigger, Popover, Container, InputGroup, Form, Alert } from 'react-bootstrap';
import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { currencyFormater, getImage }from '../general/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectAgreement from '../general/selectAgreement';
import AlertModal from '../general/alertModal';
import { useParams } from 'react-router-dom';

function Products(props) {
    const { setIsLoading, productSel, roles} = props;

    const { agreementSel } = useParams();

    const [isCreatingOrder] = useState(props.isCreatingOrder);
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [agreement, setAgreement] = useState((props.agreement || agreementSel) || '');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    const navigate = useNavigate();

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');

    useEffect(() => {
        
        if (products.length && productSel) {
            let item = products.filter(product => product.id == productSel);
            if (item.length) {
                selectProduct(item[0]);
                props.setProductSel('');
            } 
        } 
    }, [products]);

    useEffect(() => {
        if (agreement) {
            getProducts();
        } else {
            setProducts([]);
            setMessage('Selecciona un contrato para ver los productos');
        }
    }, [agreement]);

    const openImg = async (e) => {
        setAlertModalShow(true);
        setAlertImgSrc(e.target.src);
        setAlertModalMsg('');
        setAlertModalType('');
    }

    const selectProduct = (item) => {

        if (isCreatingOrder) {
            props.addProduct(item);
        } else {
            navigate(`/order/create/${agreement}/${item.id}`);
        }
    }

    const getProducts = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/products/agreements/${agreement}`);
            console.log(response.data);
            setProducts(response.data);

            if (!response.data.length) {
                setMessageType('warning');
                setMessage('El contrato que seleccionaste no tiene productos asociados');
            } else {
                setMessage('');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Row>
                <Col md={2} xs={12}> <legend>Productos: </legend></Col>
                <Col md={7} xs={12}>
                    <SelectAgreement agreement={agreement} setAgreement={setAgreement} disabled={isCreatingOrder}/>
                </Col>
                <Col md={3} xs={12}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder='Filtrar por producto o descripción'
                            type="text"
                            value={searchText}
                            onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                            disabled={!agreement}
                        />
                        <Button variant="dark" id="button-addon1" onClick={() => setSearchText('')}>
                            Limpiar
                        </Button>
                    </InputGroup>
                </Col>
                <Row>
                    <hr />
                </Row>
                <Row>
                    {message &&
                        <Alert variant={messageType || 'info'}>
                            {message}
                        </Alert>
                    }
                </Row >
            </Row >
            <Row>
                {products.filter((item) => {
                    return item.name.toLowerCase().includes(searchText.toLowerCase()) || item.description.toLowerCase().includes(searchText.toLowerCase());
                }).map(item => (
                    <Col md={4} xs={12} key={'products' + item.id}>
                        <Card className="" >
                            <Card.Header>
                                <Card.Title>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            {item.ext_id}{': '}{item.name}
                                            {' '}
                                        </Col>
                                    </Row>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row >
                                    <Col md={5} xs={5}>
                                        <h5>Valor:</h5>
                                    </Col>
                                    <Col md={7} xs={7} align="end">
                                        <h5>
                                            {currencyFormater(Math.round(parseFloat(item.price) * ((parseFloat(item.taxes) / 100) + 1)))}
                                        </h5>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <Card.Img className="pointer" onClick={(e) => openImg(e)} src={getImage(item.img_url)} />
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Card.Text>
                                            <OverlayTrigger placement="bottom"
                                                overlay={
                                                    <Popover id="tooltip-disabled" as={Container}>
                                                        {item.description}
                                                    </Popover >
                                                }>
                                                <span className="d-inline-block">
                                                    {item.description && item.description.slice(0, 125)}
                                                    {item.description &&  item.description.length > 125 ? '...' : ''}
                                                </span>
                                            </OverlayTrigger>
                                        </Card.Text>
                                    </Col>
                                    <Card.Text></Card.Text>
                                    { (roles.includes('customer_order') || roles.includes('provider_order')) &&
                                    <Button variant="primary" onClick={() => selectProduct(item)}>
                                        {isCreatingOrder ? 'Agregar al ' : 'Crear'} {' Pedido'}
                                    </Button>
                                    }
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Row>

                                    <Col md={6} xs={6}>
                                        <small className="text-muted"> IVA/Impuesto {item.taxes}{'%'}</small>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <small className="text-muted" aligned="end"> {item.id ? 'Id' : ''} {item.ext_id}</small>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                        <br />
                    </Col>
                ))}
            </Row>
            <AlertModal
             show={alertModalShow}
             setShow={setAlertModalShow}
             msg={alertModalMsg} setMsg={setAlertModalMsg}
             imgSrc={alertImgSrc}
             variant={alertModalType}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        roles: state.roles,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

