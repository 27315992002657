
import axiosInstance from './axiosInstance';

const initialState = {};
 
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return { 
        ...state,
        isLoggedIn: true,
        roles: action.payload.roles || [],
        organization: action.payload.organization,
        username: action.payload.name || '',
        userId: action.payload.id || '',
        is_provider: action.payload.is_provider || false,
        organization_id: action.payload.organization_id,
        isLoading: false,
      };
    case 'LOGOUT':
      return { ...state,
        isLoggedIn: false,
        username: '',
        roles: [],
        organization: [],
        isLoading: false,
      };
    case 'LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

//const store = createStore(rootReducer);

export default rootReducer;
