import { Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import OrderCreate from './manage';
import { useParams, useNavigate } from 'react-router-dom';

function Edit(props) {

    const navigate = useNavigate();

    const { setIsLoading, roles, readonly } = props;

    const {order} = useParams();

    const [orderId, setOrderId] = useState('');
    const [orderIdToSearch, setOrderIdToSearch] = useState('');
    const [orderData, setOrderData] = useState([]);
    
    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    // Alert Msg
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(()=>{
        if(order){
            searchOrder(order);
        }
    }, [order]);


    const setOrderDataToBeUpdated =(data) => {
        let orderDetail = [];
        data.order_detail.forEach(detail => {
            orderDetail.push({
                id_detail: detail.id,
                quantity: detail.quantity,
                id: detail.product.id,
                ext_id: detail.product.ext_id,
                name: detail.product.name,
                taxes: parseFloat(detail.taxes) || 0,
                img_url: detail.product.img_url,
                description: detail.product.description,
                price: parseFloat(detail.price) || 0,
                product_type: {name: detail.product.product_type.name}
            });
        });

        data.order_detail = orderDetail;
        
        // Set delivery date formated
        const arrDate = data.delivery_date.split('/');
        const date = new Date(`${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`);
        data.delivery_date = date.toISOString().slice(0, 10);

        setOrderData(data);

        if (data.order_status.id > 2 && !roles.includes('provider_order') && readonly) {
            setAlertModalShow(true);
            setAlertModalMsg('No se puede modificar un pedido aprobado por el proveedor');
            setAlertModalOnClose(()=>()=>{navigate('/order/search/' + data.id)});
        }
    }

    const searchOrder = async (id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/orders/${id}`);

            let idFound = response.data.id;
            if (idFound) {
                setMessage('');
                setOrderId(idFound);
                
                setOrderDataToBeUpdated(response.data)
            } else {
                setOrderId('');
                setMessageType('info');
                setMessage('Pedido no encontrado');
            }

            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Tipo de produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setOrderIdToSearch('');
            setIsLoading(false);
        }
    }

    return (
        <>
            <Row>
                <Col md={3} xs={12}> <legend>Consultar Pedido: </legend></Col>
                <Col md={4} xs={12}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            value={orderIdToSearch}
                            onChange={(e) => {e.preventDefault(); setOrderIdToSearch(e.target.value);}}
                            onKeyPress={(e) => {e.key === "Enter" && searchOrder(e.target.value)}}
                            placeholder='Número de pedido'
                        />
                        <Button variant="dark" disabled={!orderIdToSearch} id="button-addon1"  onClick={() => searchOrder(orderIdToSearch)}>
                            Buscar Pedido
                        </Button>
                        {' '}
                    </InputGroup>
                </Col>
                <Row>
                    <hr />
                </Row>
            </Row>
            <Row>
                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
            </Row>
            <Row>
                <Col>
                    {orderId &&
                        <OrderCreate orderId={orderId}  setOrderId={setOrderId}   orderData={orderData} readonly={!readonly}/>
                    }
                </Col>
            </Row>

            <AlertModal
                show={alertModalShow} setShow={setAlertModalShow}
                msg={alertModalMsg} setMsg={setAlertModalMsg}
                onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
                onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        roles: state.roles,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);