import { Form, Button, Row, Col, Table, Alert, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';

function DepartmentsList(props) {
    const { setIsLoading, agreement } = props;

    const [items, setItems] = useState([]);
    const [isCreation, setIsCreation] = useState(props.isCreation);
    const [idEdit, setIdEdit] = useState(props.idEdit);

    const [searchText, setSearchText] = useState('');

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(()=>{
        props.setIsCreation(isCreation);
    }, [isCreation]);

    const getList = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/departments/current-company-user/`);

            setItems(response.data);
            if (!response.data.length) {
                setMessage('');

            } else {
                setMessage('');
            }
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');
            setAlertModalMsg(error.response.data.message)
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (!items.length) {
            getList();
        } 
    }, []);

    return <>
        {(!idEdit && !isCreation) &&
            <>
                <Row>
                    <Col md={5} xs={12}> <legend>Centros de costos</legend></Col>
                    <Col md={4} xs={12}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder='Buscar por centro de costos'
                                type="text"
                                value={searchText}
                                onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                            />
                            <Button variant="outline-dark" onClick={() => setSearchText('')}>
                                Limpiar
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={3} xs={6} align="end">
                        <Button variant="dark" onClick={() => setIsCreation(true)}>
                            Crear Centro de costos
                        </Button>
                    </Col>
                </Row>
                <hr />
                <br />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Centro de costos</th>
                            <th>Activo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(items.length) ? items.filter((item) => {
                            return item.name && item.name.toLowerCase().includes(searchText.toLowerCase()) 
                            || item.ext_id && String(item.ext_id).includes(searchText.toLowerCase())
                            ;
                        }).map(item => (
                            <tr key={'dep' + item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.is_active ? 'Si' : 'No'}</td>
                                <td>
                                    <Button variant="dark" onClick={(e) => props.setEditData(item)}>Editar/Asignar</Button>
                                </td>
                            </tr>
                        )) : <tr><td></td></tr>}
                    </tbody>
                </Table>
            </>
        }
        {message &&
            <Alert variant={messageType || 'warning'}>
                {message}
            </Alert>
        }
         <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsList);