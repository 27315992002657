import { Row, Col, Table, Alert, Button, InputGroup, Form, Modal, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import { currencyFormater, getImage, LOGO_IMG_PATH } from '../general/utils';
import AlertModal from '../general/alertModal';

import Print from '../order/print';
import Log from './log';

function OrderSummary(props) {
    const { setIsLoading, is_provider, getOrders, orders, isApproving } = props;

     // Print
    const [idPrint, setIdPrint] = useState(false);
    const [showPrint, setShowPrint] = useState(false);

     // Log
    const [idOrderLog, setIdOrderLog] = useState(false);
    const [showLog, setShowLog] = useState(false);

    const [modalDetails, setModalDetails] = useState([]);
    const [modalHeader, setModalHeader] = useState({});

    const [showModal, setShowModal] = useState(false);

    const [searchText, setSearchText] = useState('');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');

    const openImg = async (e) => {
        setAlertModalShow(true);
        setAlertImgSrc(e.target.src);
        setAlertModalMsg('');
    }

    const getColorStatus = (idStatus)=>{
        switch (idStatus) {
            case 1:
                return 'warning';
            case 2:
                return 'primary';
            case 3:
                return 'success';
        
            default:
                return 'info';
        }
    }

    const approve = async (id)=>{
        setIsLoading(true);
        try {
            const response = await axiosInstance.put('/orders/approve/'+id);
            console.log(response.data);

            if (response.status === 200) {
                setMessageType('success');
                setMessage('Pedido ' + id + ' aprobado');
                getOrders();
            } else {
                setMessageType('danger');
                setMessage('Error actualizando el pedido');
            }
        } catch (error) {
            setMessageType('danger');
            setMessage('Error actualizando el pedido');
            console.log(error);
        } finally {
            setIsLoading(false);
            setShowModal(false);
        }
    };

    // Initialized
    useEffect(() => {
        if (!orders.length) {
            getOrders();
        }
    }, []);

    useEffect(() => {
        setMessage('');
    }, [orders]);
    return <>
        {isApproving ? <>
        <Row>
            <Col md={8} xs={12}> <legend>Pedidos {isApproving ? 'por aprobar' : 'proximos a entregar' }</legend></Col>
            <Col md={4} xs={12}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder='Filtrar por pedido'
                            type="text"
                            value={searchText}
                            onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
                        />
                        <Button variant="dark" id="button-addon1" onClick={() => setSearchText('')}>
                            Limpiar
                        </Button>
                    </InputGroup>
                </Col>
        </Row>
        <Row>
            <hr />
        </Row>
        </>
        :''}
        {message &&
            <Alert variant={messageType || 'warning'}>
                {message}
            </Alert>
        }

            <div align="end">
                <Button variant="primary" title='Actualizar Resultados' onClick={() => getOrders()}>
                    <i className='fa fa-rotate-right' />
                </Button>
            </div>
        {orders.length ? <>
        <Col md={12} xs={12}>
            <Table responsive className="table table-hover" id='funds'>
                <thead>
                    <tr >
                        <th></th>
                        <th>Pedido</th>
                        <th>Estado</th>
                        <th>{is_provider ? 'Cliente' : 'Proveedor'}</th>
                        <th>Centro de Costos</th>
                        <th>Fecha Creación</th>
                        <th>Fecha y Hora Entrega</th>
                        <th>Valor Total</th>
                    </tr>
                </thead>
                <tbody>
                {orders.filter((item) => {
                    if (!searchText.length) return true;
                    return (item && item.id == searchText)
                }).map(item => (
                        <tr key={'orders' + item.id}>
                            <td align='center'>
                                <Button onClick={() => {setShowLog(true); setIdOrderLog(item.id)} }
                                    variant="outline-info" title="Ver log de cambios"
                                >
                                    <i className='fa fa-history'/>
                                </Button>{' '}
                                <Button 
                                    onClick={() => {setModalDetails(item.order_detail); setModalHeader(item); setShowModal(true);}}
                                    variant="primary" title="Ver detalle aprobar">
                                    <i className='fa fa-search' />
                                </Button>{' '}
                                <Button variant="warning" title="Imprimir" onClick={()=>{setIdPrint(item.id); setShowPrint(true);}}>
                                    <i className='fa fa-print' />
                                </Button>{' '}
                            </td>
                            <td>{item.id}</td>
                            <td><Badge bg={getColorStatus(item.order_status.id)}>{item.order_status.id}: {item.order_status.description}</Badge></td>
                            <td>{is_provider ? <>
                                {item.agreement.customer.id}: {item.agreement.customer.ext_id}-{item.agreement.customer.name}
                                </> :<>
                                <img width="40rem" src={LOGO_IMG_PATH + item.agreement.provider.id + '.png'}/>
                                {item.agreement.provider.id}: {item.agreement.provider.ext_id}-{item.agreement.provider.name}
                                </>

                            }</td>
                            <td>{item.fund.department.id}: {item.fund.department.name}</td>
                            <td>{item.created_at}</td>
                            <td>{item.delivery_date} {item.delivery_time}</td>
                            <td>{currencyFormater(item.total_amount)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Col>
        </>
        : ''}
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    Detalle del Pedido <Badge bg="secondary">{modalHeader.id}</Badge>
                    {'  '}
                    Valor Total {currencyFormater(modalHeader.total_amount)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3} xs={3}><b>{is_provider ?  'Cliente' : 'Proveedor'}</b></Col>
                        <Col md={9} xs={9}>
                        {is_provider ? <>

                            {modalHeader.agreement && modalHeader.agreement.customer.id}:{' '}
                            {modalHeader.agreement && modalHeader.agreement.customer.ext_id} {' '}
                            {modalHeader.agreement && modalHeader.agreement.customer.name}
                        </> : <>
                            {modalHeader.agreement && modalHeader.agreement.provider.id}:{' '}
                            {modalHeader.agreement && modalHeader.agreement.provider.ext_id} {' '}
                            {modalHeader.agreement && modalHeader.agreement.provider.name}
                        </>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={3}><b>Contrato</b></Col>
                        <Col md={9} xs={9}>
                            {modalHeader.agreement && modalHeader.agreement.id}:{' '}
                            {modalHeader.agreement && modalHeader.agreement.ext_id} {' '}
                            {modalHeader.agreement && modalHeader.agreement.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={3}><b>Fondo</b></Col>
                        <Col md={9} xs={9}>
                            {modalHeader.fund && modalHeader.fund.id}:{' '}
                            {modalHeader.fund && modalHeader.fund.ext_id} {' '}
                            {modalHeader.fund && modalHeader.fund.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={3}><b>Fecha Entrega</b></Col>
                        <Col md={9} xs={9}>{modalHeader.delivery_date} {modalHeader.delivery_time}</Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={3}><b>Evento</b></Col>
                        <Col md={9} xs={9}>{modalHeader.delivery_event_name} </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={3}><b>Dirección Entrega</b></Col>
                        <Col md={9} xs={9}>{modalHeader.delivery_address} </Col>
                    </Row>
                    <hr></hr>
                    <Table responsive className="table table-hover" >
                        <thead>
                            <tr>
                                <th></th>
                                <th>Producto</th>
                                <th>Ext Id</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Impuesto/IVA</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>

                        {modalDetails.map(det=>(
                            <tr key={'modal' + det.id}>
                                <td><img className="pointer" width={'50rem'} onClick={(e) => openImg(e)} src={getImage(det.product.img_url)}></img> </td>
                                <td>{det.product.id}: {det.product.name}</td>
                                <td>{det.product.ext_id}</td>
                                <td>{det.quantity}</td>
                                <td>
                                    {currencyFormater(Math.round(parseFloat(det.price) * ((parseFloat(det.taxes) / 100) + 1)))}
                                </td>
                                <td>{det.taxes}%</td>
                                <td>{currencyFormater(det.total_amount)}</td>
                            </tr>
                        )
                        )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer >
                    {isApproving ? 
                    <Button 
                        onClick={() => approve(modalHeader.id)}
                        variant="success" title="Aprobar">
                        <i className='fa fa-check' />
                        {' '}Aprobar Pedido
                    </Button>
                    :
                    <Button 
                        onClick={() => setShowModal(false)}
                        variant="dark" title="Aprobar">
                        Cerrar
                    </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Print show={showPrint} setShow={setShowPrint} idPrint={idPrint} setIdPrint={setIdPrint} />
            <Log show={showLog} setShow={setShowLog} orderId={idOrderLog} setOrderId={setIdOrderLog} />
        <AlertModal show={alertModalShow} setShow={setAlertModalShow} msg={alertModalMsg} imgSrc={alertImgSrc} />
    </>
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
