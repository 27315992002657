import { Form, Button, Row, Col, Table, Alert, Image, Collapse, OverlayTrigger, Popover, Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsLoading } from '../general/actions';
import { getImage } from '../general/utils';
import {currencyFormater} from '../general/utils';

function OrderDetails(props) {
    const { setIsLoading, readonly } = props;

    const [products, setProducts] = useState(props.products);
    const [itemsToDelete, setItemsToDelete] = useState(props.itemsToDelete);
    const [totalOrder, setTotalOrder] = useState(props.totalOrder);

    useEffect(() => {
        setProducts(props.products);
    }, [props.products]);

    useEffect(() => {
        setAllTotalOrder()
    }, [products]);

    useEffect(() => {
        props.setTotalOrder(totalOrder);
    }, [totalOrder]);

    useEffect(() => {
        setItemsToDelete(props.itemsToDelete);
    }, [props.itemsToDelete]);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');

    const openImg = async (e) => {
        console.log(e.target.src)
        setAlertModalShow(true);
        setAlertImgSrc(e.target.src);
        setAlertModalMsg('');
    }

    const deleteProduct = (id, idToDelete) => {
        if (idToDelete) {
            props.setItemsToDelete([...itemsToDelete, idToDelete]);
        }

        props.setProducts(products.filter((product) => product.id !== id));
    }

    const setQuantity = (item, quantity) => {
        if (quantity < 1) {
            quantity = 1;
        }
        props.setProducts(products.map(x => {
            if (x.id === item.id) {
                return { ...x, quantity: quantity };
            } else {
                return { ...x };
            }
        }));

    }

    const setAllTotalOrder = ()=>{
        let total = 0;
        
        props.products.map(product => {
            total += calculateItemTotalOrder(product);
            console.log(total);
        });

        
        setTotalOrder(total);
    }

    const calculateItemTotalOrder = (item)=>{
        let total = Math.round((parseFloat(item.price) * parseInt(item.quantity) ) * ((parseFloat(item.taxes) / 100) + 1)) ;
       
        return total;
    };

    return (
        <>
        { products.length ?
        <>
            <Table responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th>Det Id</th>
                        <th>Product Id</th>
                        <th>Ext Id</th>
                        <th>Producto</th>
                        <th>Descripción</th>
                        <th>Tipo</th>
                        <th>Precio</th>
                        <th>IVA/Impuesto</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(item => (
                        <tr key={'detail-'+item.id}>
                            <td>
                                <Image onClick={(e) => openImg(e)}
                                    width="40rem" rounded
                                    src={getImage(item.img_url)}
                                />
                            </td>
                            <td>{item.id_detail}</td>
                            <td>{item.id}</td>
                            <td>{item.ext_id}</td>
                            <td>{item.name}</td>
                            <td>
                                <OverlayTrigger placement="bottom"
                                    overlay={
                                        <Popover id="tooltip-disabled" >
                                            <Container>{item.description}</Container>
                                        </Popover >
                                    }>
                                    <span className="d-inline-block">
                                        {item.description.slice(0, 80)}
                                        {item.description.length > 80 ? '...' : ''}
                                    </span>
                                </OverlayTrigger>
                            </td>
                            <td>{item.product_type_id}{' '}{item.product_type.name}</td>
                            <td>{currencyFormater(item.price)}</td>
                            <td>{item.taxes}%</td>
                            <td>
                                <Form.Control disabled={readonly} type="number" placeholder="" value={item.quantity} onChange={(e) => setQuantity(item, e.target.value)} />
                                {!readonly &&
                                <>
                                    <br></br>
                                    <Button variant="outline-danger" title="-"
                                    onClick={()=>{setQuantity(item, parseInt(item.quantity) - 1);}}>
                                        <i className='fa fa-minus' />
                                    </Button>{' '}
                                    <Button variant="outline-success" title="+"
                                    onClick={()=>{setQuantity(item, parseInt(item.quantity) + 1);}}>
                                        <i className='fa fa-plus' />
                                    </Button>
                                </>}
                            </td>
                            <td>{currencyFormater(calculateItemTotalOrder(item))}</td>
                            <td>
                                <Button disabled={readonly} ariant="outline-dark" onClick={(e) => deleteProduct(item.id, item.id_detail)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <h1 align="end">
                {'Total: '}
                {currencyFormater(totalOrder)}
            </h1>
            </>
            : <></>
        }
        </>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);