import { Form, Row, Col, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { login, setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import axios from 'axios';

function Login(props) {
  const { isLoggedIn, login, setIsLoading } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Alert Modal
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [alertModalMsg, setAlertModalMsg] = useState('');

  useEffect(() => {
    setIsLoading(false);
    if (isLoggedIn) {
      navigate("/main");
    }
  }, [isLoggedIn]);

  const loginOnServer = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      setIsLoading(true);

      axios.post('/login', { email, password })
        .then(response => {
          setIsLoading(false);

          login(response.data.user);

          localStorage.setItem('token', response.data.token);

          navigate('/main');
        })
        .catch(error => {
          setIsLoading(false);

          setAlertModalShow(true);
          if (error && error.response &&error.response.status == 401) {
            setAlertModalMsg('Usuario o contraseña incorrecto');
          } else if (error.code == 'ERR_NETWORK') {
            setAlertModalMsg('No hay conexión a internet');
          } else {
            setAlertModalMsg('Error desconocido: ' + error.message);
          }
        });
    } catch (error) {
      setIsLoading(false);
      setAlertModalShow(true);
      setAlertModalMsg(error.message);
      console.error(error);
    }
  };

  return (
    <>
      <Form onSubmit={loginOnServer} >
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={12}>
            <legend>Ingresar al Sistema</legend>
          </Col>
        </Row>
        <hr/>
        <br/>
        <Row>

          <Col xs={12} md={3}></Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="" onChange={(e) => setEmail(e.target.value)} />
              <Form.Text className="text-muted">
                No compartiremos tu email con nadie.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
          </Col>
          <Col xs={12} md={7} style={{ display: 'none' }}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Recordar sessión" />
            </Form.Group>
          </Col>

        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6} align="center">
            <Button variant="dark" type="submit">
              Ingresar
            </Button>
            {' '}
            <Button as={Link} to="/home" variant="outline-dark" >Regresar</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6} align="end">
            <Link to="/recover-password">Olvidé mi contraseña</Link>
          </Col>
        </Row>
      </Form>
      <AlertModal show={alertModalShow} setShow={setAlertModalShow} msg={alertModalMsg} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    username: state.username,
    isLoggedIn: state.isLoggedIn
  };
}

const mapDispatchToProps = {
  login,
  setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
