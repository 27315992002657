import { connect } from 'react-redux';
import { Row, Alert } from 'react-bootstrap';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';

import OrderSummary from './summary';

function Approve(props) {
    const { setIsLoading, is_provider } = props;

    const [orders, setOrders] = useState([]);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    const getOrders = async (noShowLoading) => {
        if(!noShowLoading) {
            setIsLoading(true);
        }
        try {
            const response = await axiosInstance.get('/orders/pending');
            console.log(response.data);

            setOrders(response.data);

            if (response.data.length) {
                setMessage('');
            } else {
                setMessageType('info');
                setMessage('No tiene pedidos pendientes por aprobar');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    // Initialized
    useEffect(() => {
        if (!orders.length) {
            getOrders();
        }

        const intervalId = setInterval(() => {
            getOrders(true);
        }, 60000); // 1 minutes = 60000

        return () => {
            clearInterval(intervalId);
        };

    }, []);

    return <>
        <Row>
            {message &&
                <Alert variant={messageType || 'info'}>
                    {message}
                </Alert>
            }
        </Row >
        <OrderSummary getOrders={getOrders} orders={orders} isApproving={true}/>
    </>
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Approve);
