import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../images/logo.png';
import { connect } from 'react-redux';
import { Outlet, Link } from "react-router-dom";
import React, { useState } from 'react';
import { LOGO_IMG_PATH } from '../general/utils';

function Navigation(props) {
  const { isLoggedIn, username, organization_id, roles, organization} = props;
  const [isOpen, setIsOpen] = useState(false);

  const env =  process.env.REACT_APP_ENV;

  const closeNavBar = (e, value) => {
    if (e.target.classList[0] == 'dropdown-toggle') {
      return setIsOpen(!value);
    }
    setIsOpen(value);
  }

  const getNameInitLeters = (username) => {
    if (!username) {
      return 'New';
    }
    let arrName = username.split(' ');
    return [ (arrName[0] || ''), (arrName[1] || '')].map(a=>a[0]).join('');
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top" expanded={isOpen} onClick={(e) => closeNavBar(e, !isOpen)}>
        <Container fluid>
          <Navbar.Brand >
            <img src={logo} height="30" className="d-inline-block align-top rounded"/>
            {isLoggedIn && organization ? <>
              {' '}
              <img  height="30" src={LOGO_IMG_PATH + organization.id + '.png'} className='rounded'/>
              {' '}
              {organization && organization.name ? organization.name : 'Usuario sin entidad asignada' } 
            </>
            :
              ' ¡Bienvenido!'
            }
            {env === 'qa' ?
              <b className='text-danger'> Ambiente de pruebas</b>
            : ''}
            {env === 'dev' ?
              <b className='text-danger'> Ambiente de desarrollo</b>
            : ''}
          </Navbar.Brand>
          {
            isLoggedIn &&
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="me-auto">
                  <Nav.Link onClick={(e) => closeNavBar(e, false)} as={Link} to="/main" ><i className='fa fa-home' />{' '}Inicio</Nav.Link>
                  {organization_id &&
                    <>
                      <Nav.Link onClick={(e) => closeNavBar(e, false)} as={Link} to="/products" >
                        <i className='fa fa-shopping-cart' />{' '}
                        Productos
                      </Nav.Link>
                      <NavDropdown title="Pedidos" id="basic-nav-dropdown" >
                        {roles.includes('customer_order') &&
                          <>
                            <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/order/create">Crear</NavDropdown.Item>
                          </>
                        }
                        {(roles.includes('customer_order') || roles.includes('provider_order')) &&
                          <>
                            <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/order/edit">Modificar</NavDropdown.Item>
                          </>
                        }
                        {(roles.includes('customer_approver') || roles.includes('provider_approver')) &&
                          <>
                            <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/order/approve">Aprobar</NavDropdown.Item>
                          </>
                        }
                        <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/order/search">Consultar</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Reportes" id="basic-nav-dropdown">
                      {(roles.includes('customer_admin') || roles.includes('provider_admin') || roles.includes('customer_reader') || roles.includes('provider_reader')) &&  
                      <>
                        <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/report/monthly">Consolidado Mes</NavDropdown.Item>
                        <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/report/funds">Fondos</NavDropdown.Item>
                      </>
                      }
                        <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/report/orders">Pedidos</NavDropdown.Item>
                      </NavDropdown>
                      {(roles.includes('customer_admin') || roles.includes('provider_admin') || roles.includes('superadmin')) &&
                        <>
                          <NavDropdown title="Configuración" id="basic-nav-dropdown">

                            {(roles.includes('customer_admin') || roles.includes('provider_admin')) &&
                              <>
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/organization">Entidad</NavDropdown.Item>
                              </>
                            }
                            
                            {(roles.includes('customer_admin')) &&
                              <>
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/departments">Centros de costos</NavDropdown.Item>
                              </>
                            }

                            {roles.includes('provider_admin') &&
                              <>
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/product-types">Tipos de Producto</NavDropdown.Item>
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/products">Productos</NavDropdown.Item>
                              </>
                            }
                            {(roles.includes('customer_admin') || roles.includes('provider_admin')) &&
                              <>
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/funds">Fondos</NavDropdown.Item>
                              </>
                            }

                            { ( roles.includes('superadmin') || roles.includes('customer_admin') || roles.includes('provider_admin') ) &&
                                <NavDropdown.Item onClick={(e) => closeNavBar(e, false)} as={Link} to="/config/users">Usuarios</NavDropdown.Item>
                            }
                          </NavDropdown>
                        </>
                      }
                    </>
                  }
                </Nav>
                <Nav className="mr-auto">
                  <Nav.Link onClick={(e) => closeNavBar(e, false)} as={Link} to="/help">
                    <i className='fa fa-question' />{' '}
                    Ayuda
                  </Nav.Link>
                  <Nav.Link onClick={(e) => closeNavBar(e, false)} as={Link} to="/user">
                    <span className='profile-icon'>

                    <span >
                      {getNameInitLeters(username)}
                    </span>
                    </span>
                    {' '}
                    {username}
                  </Nav.Link>
                  <Nav.Link onClick={(e) => closeNavBar(e, false)} as={Link} to="/logout">
                    <i className='fa fa-sign-out' />{' '}
                    Salir
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </>
          }
        </Container>
      </Navbar>
      <Container fluid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Outlet/>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {
    username: state.username,
    organization_id: state.organization_id,
    roles: state.roles,
    organization: state.organization,
    isLoggedIn: state.isLoggedIn,
  };
};

export default connect(mapStateToProps)(Navigation);