import React, { useEffect, useState } from 'react';
import { Button, Modal, Alert, Container } from 'react-bootstrap';

function AlertModal(props) {
    const [variant, setVariant] = useState(!props.variant  ? 'light' : props.variant);
    const [show, setShow] = useState(props.show);
    const [msg, setMsg] = useState(props.msg);
    const [imgSrc, setImgSrc] = useState(props.imgSrc);
    const [title, setTtle] = useState(props.title || '');

    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }

        // Close the modal
        setShow(false);
        props.setShow(false);

        (props.setOnClose ) && props.setOnClose(null);
        (props.setOnConfirm ) && props.setOnConfirm(null);
        
    }

    const onConfirm = () => {
        if (typeof props.onConfirm === 'function') {
            props.onConfirm();
            props.setOnConfirm(null);
        }       
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setMsg(props.msg);
    }, [props.msg]);

    useEffect(() => {
        setVariant(props.variant);
    }, [props.variant]);

    useEffect(() => {
        setImgSrc(props.imgSrc);
    }, [props.imgSrc]);

    return (
        <Modal 
            show={show} onHide={onClose} centered
            size={imgSrc ? 'lg' : ''}  backdrop="static"
            variant={variant} as={Alert}
        >
            <Modal.Header closeButton >
                <Modal.Title>Abi {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body variant={variant} as={Alert}>
                <Container >
                    <p>
                        {msg}
                    </p>
                </Container>
                {imgSrc &&
                    <img src={imgSrc} width="80%" className="rounded mx-auto d-block"/>
                }

            </Modal.Body>
            <Modal.Footer>
                { (typeof props.onConfirm === 'function') &&
                <Button variant="primary" onClick={onConfirm}>
                    Aceptar
                </Button>
                }
                <Button variant="dark" onClick={onClose}>
                { (typeof props.onConfirm === 'function') ? 'Cancelar' : 'Cerrar' }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlertModal;