
import { Row, Col, Table, Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setIsLoading } from '../general/actions';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../general/axiosInstance';
import { Link } from 'react-router-dom';
import { currencyFormater, LOGO_IMG_PATH } from '../general/utils';

function AgreementList(props) {
    const { setIsLoading, showProductsBtn, organization_id } = props;

    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const [agreements, setAgreements] = useState([]);

    const getAgreements = () => {
        if (!organization_id) {
            return;
        }
        setIsLoading(true);
        axiosInstance.get('/agreements/current-organization')
            .then(res => {
                setIsLoading(false);

                if (res && res.data && res.data.length) {
                    setAgreements(res.data);
                    setMessage('')
                } else {
                    setMessage('No hay contratos en el sistema')
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    // init
    useEffect(() => {
        getAgreements();
    }, []);

    return (<>
        {!showProductsBtn ?
            <Row>
                <Col md={9} xs={12}> <legend>Contratos:</legend></Col>
                <hr />
            </Row>
            : ''
        }
        <Table responsive>
            <tbody>
                {agreements.length > 0 ?

                    <tr>
                        <th>Id</th>
                        <th>Contrato</th>
                        <th>Descripción</th>
                        <th>Cliente</th>
                        <th>Proveedor</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Monto</th>
                        <th>Horas Minimas Pedido</th>
                        <th>Activo</th>
                        {showProductsBtn ?
                            <th></th> : ''
                        }
                    </tr>
                :  <tr><th>Tu entidad no tiene contratos en el sistema</th></tr>}
                {agreements && agreements.map(agreement => (

                        <tr key={agreement.id}>
                            <td>{agreement.id}</td>
                            <td>{agreement.ext_id}</td>
                            <td>{agreement.description}</td>
                            <td>{agreement.customer.id}: {agreement.customer.ext_id}-{agreement.customer.name}</td>
                            <td>
                                <img width="40rem" src={LOGO_IMG_PATH + agreement.provider.id + '.png'} />
                                {agreement.provider.id}: {agreement.provider.ext_id}-{agreement.provider.name}
                            </td>
                            <td>{agreement.start_date}</td>
                            <td>{agreement.end_date}</td>
                            <td>{currencyFormater(agreement.amount)}</td>
                            <td>{agreement.min_hours_deliver}</td>
                            <td>{agreement.is_active ? 'Si' : 'No'}</td>
                            {showProductsBtn ?
                                <td>
                                    <Button as={Link} to={"/products/" + agreement.id}>
                                        Productos
                                    </Button>
                                </td> : ''
                            }
                        </tr>
                    ))}
            </tbody>
        </Table>
        {message &&
            <Alert variant={messageType || 'warning'}>
                {message}
            </Alert>
        }

    </>);

}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        organization_id: state.organization_id,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementList);