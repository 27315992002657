import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, Collapse, Form, Row, Col } from 'react-bootstrap';
import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';

function Log(props) {
    const { setIsLoading, is_provider } = props;

    const [show, setShow] = useState(props.show);
    const [orderId, setOrderId] = useState(props.orderId);
    const [orderLog, setOrderLog] = useState([]);
    const [openLog, setOpenLog] = useState(false);

    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }

        // Close the modal
        setShow(false);
        props.setShow(false);
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setOrderId(props.orderId);
    }, [props.orderId]);

    // Initialize
    useEffect(() => {
        if (orderId) {
            getOrderData(orderId);
        }
    }, [orderId]);

    const getOrderData = async (id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/orders-log/' + id);
            console.log(response.data);

            
            if (response.data.length) {
                setOrderLog(response.data);
            } else {

            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal 
            show={show} onHide={onClose} size="lg" fullscreen={false}>
            <Modal.Header closeButton className='no-print'>
                <Modal.Title>
                    Abi: Log de Cambios Pedido: {orderId} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row >
                    <Col md={6} xs={6}>
                    </Col>
                    <Col md={6} xs={6}>
                    <Form.Group className="mb-3" controlId="visible">
                        <Form.Check type="checkbox" label="Ver Detalles de cada transacción" checked={openLog} onChange={(e) => setOpenLog(e.target.checked)} />
                    </Form.Group>
                    </Col>
                </Row>
            <Table responsive className="table table-hover" >
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Accion/Estado</th>
                                <th>Usuario</th>
                                {openLog ? 
                                    <th>Transacción</th>
                                :''}
                            </tr>
                        </thead>
                        <tbody>

                        {orderLog.map(det=>(
                            <tr key={'modal' + det.id}>
                                <td>{det.created_at}</td>
                                <td>{det.description}</td>
                                <td>{det.user.id}: {det.user.name} {det.user.email}</td>
                                <td>
                                    <Collapse in={openLog}>
                                    <div id="openLog">

                                        {det.log}
                                    </div>
                                    </Collapse>
                                </td>
                            </tr>
                        )
                        )}
                        </tbody>
                    </Table>
            </Modal.Body>
            <Modal.Footer className='no-print'>
                <Button variant="dark" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Log);