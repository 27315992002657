import { Form, Button, Row, Col, Container, Table, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import AlertModal from '../general/alertModal';

function DepartmentUsers(props) {

    const { department, setIsLoading } = props;

    const [userId, setUserId] = useState('');
    const [users, setUsers] = useState([]);
    const [departmentUsers, setDepartmentUsers] = useState([]);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);
    

    const [newUser, setNewUser] = useState([]);

    const asignUser = () => {
        setIsLoading(true);
        axiosInstance.post('users-departments',
            {
                user_id: newUser.id,
                department_id: department
            })
            .then(response => {
                console.log(response.data);

                let item = { ...response.data, user: newUser };

                setDepartmentUsers([...departmentUsers, item]);
            })
            .catch(error => {
                setIsLoading(true);
                setAlertModalShow(true);
                setAlertModalType('danger');
                if (error.response.data.message.includes('Duplicate')) {
                    setAlertModalMsg('El usuario seleccionado ya esta asignado');
                } else {
                    setAlertModalMsg(error.response.data.message);
                }
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const deleteUserDepartment = (id) => {
        setIsLoading(true);
        axiosInstance.delete('users-departments/' + id)
            .then(response => {
                getDepartmentUsers();
            })
            .catch(error => {
                console.log(error);
            }) 
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getUsers = () => {
        setIsLoading(true);
        axiosInstance.get('users/departments/organizations/current-user')
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getDepartmentUsers = () => {
        axiosInstance.get('users-departments/department/' + department)
            .then(response => {
                setDepartmentUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        if (departmentUsers.length) {
            props.setHasUsers(true);
        } else {
            props.setHasUsers(false);
        }
    }, [departmentUsers]);

    useEffect(() => {
        getUsers();
        if (department) {
            getDepartmentUsers();
        }
    }, [department]);


    const handleUserChange = (id) => {
        if (!id) {
            return;
        }
        const selectedUser = users.find((user) => user.id === parseInt(id));
        setNewUser(selectedUser);
        setUserId(id);
    };

    return (<>
        {!department ?
            <Container>
                <Alert>
                    Luego de guardar el Centro de Costos podrás asignar los usuarios aquí
                </Alert>
            </Container>
            :
            <>
                <Row>
                    <Col md={12} xs={12}>
                        <Container>
                            <b>Asignar Usuarios:</b><br></br><br></br>
                            <Form.Select value={userId} onChange={(e) => handleUserChange(e.target.value)}>
                                <option key={0} value=''>
                                    --Seleccione un usuario
                                </option>
                                {users && users.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.id}: {user.name} {user.email}
                                    </option>
                                ))}
                            </Form.Select>
                        </Container>
                    </Col>
                    <Col md={12} xs={12} align='end'>
                        <Button variant="outline-success" onClick={asignUser}>
                            <i className='fa fa-plus' /> Asignar
                        </Button>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <Table width={'100%'}>
                            <tbody>
                                {departmentUsers.length ?
                                    departmentUsers.map(rel => (
                                        <tr key={rel.id} style={{display: !rel.user ? 'none' : ''}}>
                                            <td>{rel.user?.id}: {rel.user?.name} {rel.user?.email}</td>
                                            <td>
                                                <Button variant="outline-danger" onClick={() => deleteUserDepartment(rel.id)}>
                                                    <i className='fa fa-trash' />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={2}>
                                            <Alert>
                                                No  hay usuarios asignados
                                            </Alert>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        }
         <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            variant={alertModalType}
        />
    </>
    );
};

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentUsers);