import ManageUser from "./manage";
import { useParams } from 'react-router-dom';

const Register = () => {
    const { email, organizationId } = useParams();


    return (
        <ManageUser isCreatigUser="true" invitedEmail={email} newOrganizationId={organizationId}/>
    );
};

export default Register;
