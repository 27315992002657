import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { currencyFormater } from '../general/utils';

function Print(props) {
    const { setIsLoading, is_provider } = props;

    const [order, setOrder] = useState({});
    const [orderDetail, setOrderDetail] = useState([]);

    const [fontSize, setFontSize] = useState(parseFloat(localStorage.fontSize) || 0.7);
    const [maxCharsDesc, setMaxCharsDesc] = useState(parseInt(localStorage.maxCharsDesc) || 350);
    const [printOrder, setPrintOrder] = useState(true);
    const [printRemision, setPrintRemision] = useState(is_provider);

    const [show, setShow] = useState(props.show);
    const [idPrint, setIdPrint] = useState(props.idPrint);

    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }

        // Close the modal
        setShow(false);
        props.setIdPrint('');
        props.setShow(false);
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        setIdPrint(props.idPrint);
    }, [props.idPrint]);
    
    useEffect(() => {
        localStorage.fontSize = fontSize || '0.7';
    }, [fontSize]);
    
    useEffect(() => {
        localStorage.maxCharsDesc = maxCharsDesc || '350';
    }, [maxCharsDesc]);

    // Initialize
    useEffect(() => {
        if (idPrint) {
            getOrderData(idPrint);

            setMaxCharsDesc(parseInt(localStorage.maxCharsDesc) || maxCharsDesc);
            setFontSize(parseFloat(localStorage.fontSize) || fontSize);
        }
    }, [idPrint]);

    const getOrderData = async (id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/orders/print/' + id);
            console.log(response.data);

            setOrder(response.data);
            setOrderDetail(response.data.order_detail);

            if (response.data.length) {

            } else {

            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    function handleImprimir(fontSize) {
        const contenidoImprimir = document.getElementById('print-container').innerHTML;
        const ventanaImpresion = window.open('', '_blank');
        ventanaImpresion.document.write(`
          <html>
            <head>
              <title>Impresión</title>
              <style>
                /* Estilos adicionales para la impresión */
                /* Puedes agregar estilos de fuente, tamaño, márgenes, etc. según tus necesidades */
                .border table, .border th, .border td {
                    border: 1px solid black;
                }

                body {
                    font-family: 'Calibri', sans-serif;
                    font-size: ${fontSize}rem !important;
                  }

                  .container {
                    display: flex;
                    justify-content: center;
                  }

                  .text-end {
                    text-align: right;
                  }

                  .text-start {
                    text-align: left;
                  }
                  
              </style>
            </head>
            <body>
                <div style="font-size: ${fontSize}rem !important">${contenidoImprimir}</div>
            </body>
          </html>
        `);
        ventanaImpresion.document.close();
        ventanaImpresion.print();
        ventanaImpresion.close();
      }
      

    return (
        <Modal 
            show={show} onHide={onClose} size="lg" fullscreen={true}>
            <Modal.Header closeButton className='no-print'>
                <Modal.Title>Abi:  Imprimir Pedido {idPrint}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-secondary print print-container' id='print-container' style={{ fontSize: `${fontSize}rem` }}>
                <Row className='print' >
                    <Col md={2} xs={0} className='no-print'></Col>
                    <Col className='bg-base' style={ {'display': printOrder ? 'block' : 'none' }}>
                        <p align="end">Creación: {order.created_at}</p>
                        Señores:{' '}
                        <b>{order.agreement && order.agreement.provider.name}</b>
                        <br></br>
                        {order.agreement && order.agreement.provider.address}
                        {' Tel: '}
                        {order.agreement && order.agreement.provider.phone}
                        <br></br>
                        <p align="justify">
                            Cordial saludo, favor proceder de acuerdo al contrato <b>{order.agreement && order.agreement.ext_id}</b>
                            , y suministrar la siguiente solicitud de servicio, teniendo en cuenta la calidad de los productos y oportunidad en la entrega.
                        </p>

                        <table cellSpacing="0.2rem" className='print' width={'100%'}>
                            <thead >
                                <tr >
                                    <td>
                                        <h5>Pedido: {idPrint}</h5>
                                    </td>
                                    <th className="text-start">
                                        {order.agreement && order.agreement.customer.name}
                                        {' '}
                                        Centro de Costos  {order.fund && order.fund.department.id}: {order.fund && order.fund.department.name}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="2"><b>Entrega:</b></td>
                                    <td>
                                        <b>Fecha: </b> {order.delivery_date}
                                        <b> Hora: </b> {order.delivery_time}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Dirección: </b>
                                        {order.delivery_address}
                                        <b> Evento:</b> {order.delivery_event_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Recibe:</b></td>
                                    <td>
                                        {order.delivery_person_receiving}
                                        <b> Teléfono: </b> {order.delivery_phone}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Fondo:</b></td>
                                    <td>
                                        {order.fund && order.fund.id}: {order.fund && order.fund.ext_id} {order.fund && order.fund.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Interventor Operativo:</b></td>
                                    <td>
                                        {order.user && order.user.id}: {order.user && order.user.name}
                                        <b> email: </b> {order.user && order.user.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Notas:</b></td>
                                    <td>
                                    {order.notes}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Firma Interventor Adiministrativo:</b></td>
                                    <td>

                                    ________________________________________
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <table cellSpacing="0.2rem" className='print border'  width={'100%'}>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Impuesto/IVA</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>

                                {orderDetail.map(det => (
                                    <React.Fragment  key={'det1-'+det.id}>
                                        <tr>
                                            <td>{det.product.id}: {det.product.name}</td>
                                            <td className='text-end'>{det.quantity}</td>
                                            <td className='text-end'>{currencyFormater(det.price)}</td>
                                            <td className='text-end'>{det.taxes}%</td>
                                            <td className='text-end'>{currencyFormater(det.total_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                <small>
                                                    <b>Detalle: </b>{det.product.description.slice(0, maxCharsDesc)}
                                                    {det.product.description.length > maxCharsDesc ? '...' : ''}
                                                </small>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={5} className='text-end'>
                                        Valor Total: {currencyFormater(order.total_amount)}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                        <hr></hr>
                    </Col>
                    <Col md={2} xs={0} className='no-print'></Col>
                </Row>
                {
                    // Remision
                }
                <Row className='print'>
                    <Col md={2} xs={0} className='no-print'></Col>
                    <Col className='bg-base' style={ {'display': printRemision ? 'block' : 'none' }}>

                        <table width={'100%'}>
                            <tr>
                                <th>
                                    {order.agreement && order.agreement.provider.ext_id}
                                    {' '}
                                    {order.agreement && order.agreement.provider.name}
                                    {' Teléfono: '}
                                    {order.agreement && order.agreement.provider.phone}
                                </th>
                                <td>
                                    <h5>Pedido: {idPrint}</h5>
                                </td>
                                <td>
                                    <b>Remisión: _______</b>
                                </td>
                            </tr>
                        </table>
                        <br></br>
                        <table cellSpacing="0.2rem" className=''  width={'100%'}>
                            <tbody>
                                <tr>
                                    <td><b>Cliente:</b></td>
                                    <td>
                                        {order.agreement && order.agreement.customer.ext_id}
                                        {' '}
                                        {order.agreement && order.agreement.customer.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2"><b>Entrega:</b></td>
                                    <td>
                                        <b>Fecha: </b> {order.delivery_date}
                                        <b> Hora: </b> {order.delivery_time}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Dirección: </b>
                                        {order.delivery_address}
                                        <b> Evento:</b> {order.delivery_event_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Recibe:</b></td>
                                    <td>
                                        {order.delivery_person_receiving}
                                        <b> Teléfono: </b> {order.delivery_phone}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Fondo:</b></td>
                                    <td>
                                        {order.fund && order.fund.id}: {order.fund && order.fund.ext_id}  {order.fund && order.fund.name}
                                        {' '}
                                        <b>Centro de Costos  </b>{order.fund && order.fund.department.id}: {order.fund && order.fund.department.name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <table cellSpacing="0.2rem" className='print border'  width={'100%'}>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Impuesto/IVA</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>

                                {orderDetail.map(det => (
                                    <React.Fragment  key={'det2-'+det.id}>
                                        <tr>
                                            <td>{det.product.id}: {det.product.name}</td>
                                            <td className='text-end'>{det.quantity}</td>
                                            <td className='text-end'>{currencyFormater(det.price)}</td>
                                            <td className='text-end'>{det.taxes}%</td>
                                            <td className='text-end'>{currencyFormater(det.total_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                <small>
                                                    <b>Detalle: </b>{det.product.description.slice(0, maxCharsDesc)}
                                                    {det.product.description.length > maxCharsDesc ? '...' : ''}
                                                </small>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={5} className='text-end'>
                                        Valor Total: {currencyFormater(order.total_amount)}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                        <br></br>
                        <table width={'100%'}>
                            <tr>
                                <th>
                                    Recibido:
                                </th>
                                <td>
                                    <br></br>
                                    Firma______________________________
                                </td>
                                <td>
                                    <br></br>
                                    Cc.______________________________
                                </td>
                            </tr>
                        </table>
                        <br></br>
                    </Col>
                    <Col md={2} xs={0} className='no-print'></Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className='no-print'>
                <Form.Group className="mb-3" controlId="order">
                    Cantidad caracteres descripción producto: 
                    <input type='number' value={maxCharsDesc}  onChange={(e) => setMaxCharsDesc(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="order" style={{display: 'none'}} >
                    Tamaño Letra {(fontSize*10).toFixed(2)} {' '}
                    <input type='button' value={'-'}  onClick={(e) => setFontSize(fontSize - 0.025)}/>
                    <input type='button' value={'Reset'}  onClick={(e) => setFontSize(0.7)}/>
                    <input type='button' value={'+'}  onClick={(e) => setFontSize(fontSize + 0.025)}/>
                </Form.Group>
                {is_provider && <>
                <Form.Group className="mb-3" controlId="order">
                    <Form.Check type="checkbox" label="Pedido" checked={printOrder} onChange={(e) => setPrintOrder(e.target.checked)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="remision">
                    <Form.Check type="checkbox" label="Remisión" checked={printRemision} onChange={(e) => setPrintRemision(e.target.checked)}/>
                </Form.Group>
                </>
                }
                <Button variant="warning" onClick={()=>handleImprimir(fontSize)}>
                    Imprimir
                </Button>
                <Button variant="warning" onClick={() => window.print()} style={{display: 'none'}}>
                    Imprimir
                </Button>
                <Button variant="dark" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Print);