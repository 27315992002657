import axios from "axios";
import { logout } from '../general/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const API_HOST = process.env.REACT_APP_API_HOST;
axios.defaults.baseURL = API_HOST;

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status == 401) {
      logout();
      const currentPath = window.location.pathname;
      const loginPath = "/login";
      if (currentPath !== loginPath) {
        window.location.href = loginPath;
      }
    }
    return response;
  },
  (error) => {
    // This is only working firts request
    if (error.response.status == 500) {
      //alert('Error inesperado, por favor contacte al administrador');
    }
    if (error.response.status === 401) {
      logout();
      /* todo This should only aply when is a private  route
      const currentPath = window.location.pathname;
      const loginPath = "/login";
      if (currentPath !== loginPath &&) {
        window.location.href = loginPath;
      }
      */
    }
    return Promise.reject(error);
  }
);

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(axiosInstance);
