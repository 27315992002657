import { Form } from 'react-bootstrap';
import { setIsLoading } from '../general/actions';
import axiosInstance from "./axiosInstance";
import { connect } from 'react-redux';
import { useEffect, useState } from "react";

/**
 * This component is responsible for returning all agreements that have been
 * asociatived to the current user
 * @param {*} props 
 * @returns 
 */
function SelectAgreement(props) {
    const { setIsLoading, is_provider } = props;

    const [disabled, setDisabled] = useState(props.disabled || false);
    const [agreements, setAgreements] = useState([]);
    const [agreement, setAgreement] = useState(props.agreement || '');

    // Initialization
    useEffect(()=>{
        if (!agreements.length) {
            loadAgreements();
        }
    }, []);

    useEffect(()=>{
        props.setAgreement(agreement);
    }, [agreement]);

    const loadAgreements = () => {
        setIsLoading(true);
        axiosInstance.get('/agreements/current-user')
            .then(res => {
                setIsLoading(false);
                setAgreements(res.data);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    return (
        <Form.Group className="mb-3">
            <Form.Select value={agreement} onChange={(e) => { setAgreement(e.target.value); }} disabled={disabled}>
                <option key={'productAgreement-' + 0} value="">Selecciona un contrato</option>
                {agreements && agreements.map(item => (
                    <option key={'productAgreement-' + item.id} value={item.id}>
                        {item.id}: {item.ext_id} {'-'} {item.description} {' | '} 
                        {is_provider ? <>
                            {item.customer.id}: {item.customer.ext_id}  {'-'} {item.customer.name}
                        </>
                        : <>
                            {item.provider.id}: {item.provider.ext_id} {'-'} {item.provider.name}
                        </>}
                        
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

};


const mapStateToProps = state => {
    return {
        is_provider: state.is_provider,
    };
  };
const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAgreement);