import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

function Home(props) {
    const { isLoggedIn } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/main");
        }
    }, [isLoggedIn]);

    return (
        <>{!isLoggedIn &&
            <div className="text-center">
                <br />
                <br />
                <br />
                <Row>
                    <Col>
                        <Alert variant="outline-dark">
                            <Alert.Heading>Hola</Alert.Heading>
                            <p>
                                Con Abi, tendrás la solución perfecta para gestionar tus pedidos de manera efectiva y controlar tu presupuesto de forma óptima. Además, podrás generar reportes en tiempo real y acceder a muchas otras funcionalidades útiles para tu negocio y clientes.
                                <p>
                                </p>
                                ¡Únete a la experiencia Abi hoy mismo y transforma la forma en que administras tus pedidos!
                            </p>
                            <hr />
                            <p className="mb-0">

                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button as={Link} to="/login" variant="dark">Ingresar</Button>
                        {' '}
                        <Button as={Link} to="/register" variant="outline-dark">Registrarse</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={6} align="end">
                        <Link to="/recover-password">Olvidé mi contraseña</Link>
                    </Col>
                </Row>
            </div>
        }</>
    );
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);