
import { Provider } from 'react-redux';
import AppRouter from './modules/navigation/appRouter';
import StopUser from './modules/general/stopUser';
import { useEffect } from 'react';
import axiosInstance from './modules/general/axiosInstance';

import './styles/Main.css';

function App(props) {

  useEffect(() => {
    
    localStorage.setItem('count_pending_approve', 0);

    const intervalId = setInterval(() => {
      if (localStorage.token !== undefined) {
        getCountPendingOrders();
      }
    }, 60000); // 1 minutes = 60000

    return () => {
      clearInterval(intervalId);
    };

  }, []);

  const getCountPendingOrders = async () => {
    const response = await axiosInstance.get('/orders/pending-count');
    let countPendingOrders = parseInt(response.data) || 0;
    if (countPendingOrders > 0 && countPendingOrders > parseInt(localStorage.count_pending_approve)) {
      new Notification(
        'Notificación Abi:',
        {
          body: 'Tienes ' + countPendingOrders + ' pedido'+ (countPendingOrders > 1 ? 's' : '') + ' por aprobar.',
          icon: "logo192.png"
        }

      );
      localStorage.setItem('count_pending_approve', countPendingOrders);
    }
  }

  return (
    <Provider store={props.store}>
      <AppRouter />
      <StopUser />
    </Provider >
  );
}

export default App;