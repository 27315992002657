import { Form, Button, Row, Col, Alert, FormCheck } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword(props) {
    const navigate = useNavigate();
    const { token } = useParams();

    const { setIsLoading, isCreatigUser } = props;
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');
    const [show, setShow] = useState(props.show);
    const [showPassword, setShowPassword] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalOnClose, setAlertModalOnClose] = useState();

    const setPasswordInParent = (password) => {
        if (!token) {
            props.passwordConfirmed(password);
        } else {
            setShow(true);
        }
    };

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        if (isCreatigUser) {
            props.setMessage(message);
        }
    }, [message, isCreatigUser]);

    // Validate fields
    useEffect(() => {
        setPasswordInParent('');
        if (newPassword !== confirmPassword) {
            setMessageType('warning');
            setMessage('Las contraseñas no coinciden');
        } else if (!newPassword || !confirmPassword || !isPasswordValid(newPassword)) {
            setMessageType('warning');
            setMessage('La contraseña debe tener al menos 8 caracteres, incluyendo al menos un número.');
        } else {
            setPasswordInParent(newPassword);
            setMessage('');
        }
    }, [newPassword, confirmPassword]);

    function isPasswordValid(value) {
        const regex = /^(?=.*\d).{8,}$/;
        return regex.test(value);
    }

    const clearForm = () => {
        setNewPassword('');
        setConfirmPassword('');
    };

    const updatePassword = async (e) => {
        e.preventDefault();

        if(token) { 
            return updatePasswordUsingToken(e);
        }

        setIsLoading(true);

        try {
            await axiosInstance.put('/users/password', {
                newPassword,
                confirmPassword,
            })
            .then(function (response) {
                clearForm();
                setAlertModalShow(true)
                setAlertModalMsg(response.data.message);
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            setAlertModalShow(true)
            setAlertModalMsg(error.response.data.message);
        }
    }

    const updatePasswordUsingToken = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await axiosInstance.put('password/reset/'+token, {
                newPassword,
                confirmPassword,
            })
            .then(function (response) {
                clearForm();
                setAlertModalShow(true)
                setAlertModalMsg(response.data.message);
                setAlertModalOnClose(()=>()=>{navigate("/login")});
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            setAlertModalShow(true)
            setAlertModalMsg(error.response.data.message);
        }
    }

    return (
        <>
            <>
                <hr />
                <br/>
                <Form onSubmit={updatePassword}>
                    <legend>{isCreatigUser ? '' : 'Cambiar Contraseña'} </legend>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control type={showPassword ?  'text' : 'password' } placeholder="" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label>Repetir Contraseña</Form.Label>
                                <Form.Control type={showPassword ?  'text' : 'password' } placeholder="" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {message && !isCreatigUser &&
                        <Row >
                            <Col xs={12} md={8}>
                                <Alert variant={messageType}>
                                    {message}
                                </Alert>
                            </Col>
                        </Row>
                    }
                    {show &&
                        <Row>
                            <Col xs={12} md={8} align='end'>
                                <Button variant="dark" type="submit" disabled={!!message}>
                                    {isCreatigUser ? 'Crear ' : 'Cambiar '} Contraseña
                                </Button>
                            </Col>
                        </Row>
                    }
                </Form>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="visible">
                            <Form.Check 
                                type="checkbox" label="Ver contraseña"
                                checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
            <AlertModal
                show={alertModalShow} setShow={setAlertModalShow}
                msg={alertModalMsg} setMsg={setAlertModalMsg}
                onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);