import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../user/login';
import Home from '../home';
import Main from '../main';
import Logout from '../user/logout';
import Help from '../user/help';
import Register from '../user/register';
import RecoverPassword from '../user/recoverPassword';
import ResetPassword from '../user/resetPassword';
import ManageUser from '../user/manage';
import OrderCreate from '../order/manage';
import PrivateRoute from './privateRoute';
import ProductTypes from '../config/productTypes';
import Funds from '../config/funds';
import Users from '../config/users';
import Departments from '../config/departments';
import Products from '../config/products';
import Navigation from './navigation';
import { default  as  OrderSearch } from '../order/edit';
import { default  as  OrderEdit } from '../order/search';
import Approve from '../order/approve';
import {default  as Catalog} from '../catalog/products';
import FundsReport from '../report/funds';
import OrdersReport from '../report/orders';
import MonthlyReport from '../report/monthly';
import Organization from '../config/organization';

function AppRouter() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route exact path="" element={<Navigation />}>
          <Route exact path="/register/:organizationId/:email" element={<Register />} />
          <Route exact path="/register" element={<Register />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />   
          <Route exact path="/recover-password" element={<RecoverPassword />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/help" element={<Help />} />
          
          {/* Private Routes */}
          <Route path="/order/create/:agreementSel/:productSel" element={<PrivateRoute component={OrderCreate} />} />
          <Route exact path="/order/create" element={<PrivateRoute component={OrderCreate} />} />
          <Route path="/order/edit/:order" element={<PrivateRoute component={OrderEdit} />} />
          <Route exact path="/order/approve" element={<PrivateRoute component={Approve} />} />
          <Route exact path="/order/edit" element={<PrivateRoute component={OrderEdit} />} />
          <Route path="/order/search/:order" element={<PrivateRoute component={OrderSearch} />} />
          <Route exact path="/order/search" element={<PrivateRoute component={OrderSearch} />} />
          <Route exact path="/report/funds" element={<PrivateRoute component={FundsReport} />} />
          <Route exact path="/report/orders" element={<PrivateRoute component={OrdersReport} />} />
          <Route exact path="/report/monthly" element={<PrivateRoute component={MonthlyReport} />} />
          <Route exact path="/config/organization" element={<PrivateRoute component={Organization} />} />
          <Route exact path="/config/product-types" element={<PrivateRoute component={ProductTypes} />} />
          <Route exact path="/config/products" element={<PrivateRoute component={Products} />} />
          <Route exact path="/config/funds" element={<PrivateRoute component={Funds} />} />
          <Route exact path="/config/users" element={<PrivateRoute component={Users} />} />
          <Route exact path="/config/departments" element={<PrivateRoute component={Departments} />} />
          <Route exact path="/main" element={<PrivateRoute component={Main} />} />
          <Route exact path="/products/:agreementSel" element={<PrivateRoute component={Catalog} />} />
          <Route exact path="/products" element={<PrivateRoute component={Catalog} />} />
          <Route path="/logout" element={<PrivateRoute component={Logout} />} />
          <Route path="/user" element={<PrivateRoute component={ManageUser} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;
