import { connect } from 'react-redux';

function StopUser(props) {
    const { isLoading } = props;

    if (!isLoading) {
        return '';
    }

    return (
        <div id="stop-user">
            <div>
                <i className="fa fa-circle-o-notch fa-spin"></i>
                {' '}
                <span className="text">Cargando...</span>
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading
    };
}

export default connect(mapStateToProps)(StopUser);