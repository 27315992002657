import { Alert, Modal, Button, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsLoading } from '../general/actions';
import axiosInstance from '../general/axiosInstance';
import {currencyFormater} from '../general/utils';

function Funds(props) {

    const [funds, setFunds] = useState([]);

    const [show, setShow] = useState(props.show);

    // Alert Msg
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const getFunds = () => {
        setIsLoading(true);

        axiosInstance.get(`/funds/current-user`)
            .then(response => {
                setFunds(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setMessageType('danger');
                if (error.code == 'ERR_NETWORK') {
                    setMessage('No hay conexión a internet');
                } else {
                    setMessage('Error desconocido: ' + error.message);
                }
            });
    };

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        props.setShow(show);
    }, [show]);

    // Initialize
    useEffect(() => {
        getFunds();
    }, []);

    return (<>
        <Modal show={show} onHide={()=>setShow(false)} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Abi: Mis fondos asignados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }

                <Table>
                    <tbody>

                        <tr>
                            <th>Contrato</th>
                            <th>Fondo</th>
                            <th>Centro de Costos</th>
                            <th>Valor Inicial</th>
                            <th>Consumo</th>
                            <th>Disponible</th>
                            <th>Activo</th>
                        </tr>
                        {funds.length ? funds.map(fund => (
                            <tr key={'user-fund-'+fund.id}>
                                <td>
                                    {fund.agreement.id}:
                                    {fund.agreement.ext_id}
                                    {' '}
                                    {fund.agreement.description}
                                </td>
                                <td>{fund.id}:{fund.ext_id} {fund.name}</td>
                                <td>{fund.department.id}:{fund.department.name}</td>
                                <td>
                                    {currencyFormater(fund.amount)}
                                    <br></br>
                                    <progress
                                        value={(fund.spent / fund.amount) || 0}
                                        max={1} />
                                </td>
                                <td>{currencyFormater(fund.spent)}</td>
                                <td>{currencyFormater(fund.available)}</td>
                                <td>{fund.is_active ? 'Si' : 'No'}</td>
                            </tr>
                        )) :
                            <tr>   
                                <td colSpan={7}>
                                    <Alert>
                                        No tienes fondos asignados.
                                    </Alert>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={()=>setShow(false)}>
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Funds);