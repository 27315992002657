import { Form, Button, Row, Col, Table, Alert, InputGroup, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { login, setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import { useNavigate } from 'react-router-dom';

function Users(props) {
    const { setIsLoading, roles, userId } = props;

    const [newUserEmail, setNewUserEmail] = useState('');
    const [users, setUsers] = useState([]);
    const [organization, setOrganization] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const [productType, setProductType] = useState('');

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const navigate = useNavigate();

    useEffect(() => {
        setMessageType('warning');
        if (productType === '') {
            setMessage('Tipo de produto es obligatorio');
        } else {
            setMessage('');
        }
    }, [productType]);

    const getOrganizations = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/organizations');
            console.log(response.data);

            setOrganizations(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }
    
    const getRoleList = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/roles');
            console.log(response.data);

            setRolesList(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const getUsersByOrganization = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/users/organizations/' + organization);
            console.log(response.data);

            setUsers(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    const autologin = async (id, confirmed) => {
        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalType('');
            setAlertModalMsg('¿Esta seguro de hacer login con el usuario: ' + id + '?');
            setAlertModalOnConfirm(() => () => { autologin(id, true) });
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/autologin', { id });

            setIsLoading(false);

            login(response.data.user);

            localStorage.setItem('token', response.data.token);

            navigate('/main');

            window.location.reload();

        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');

            if (error.response.data.message.includes('foreign ')) {
                setAlertModalMsg("El tipo de producto no se puede elimar porque esta en uso");
            } else {
                setAlertModalMsg(error.response.data.message);
            }

            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const addUser = async (email) => {
        setIsLoading(true);
        var msg = '';
        try {
            const response = await axiosInstance.put('/users/add-organization', {
                 email,
                 organization_id: organization
            });

            msg = 'Usuario fue invitado correctamente';
            
            if (response.data.message === 'User successfully configured') {
                msg = 'Usuario asociado correctamente';
                getUsersByOrganization();
            }

            setAlertModalType('success');
            
        } catch (error) {
            msg = 'Ocurrio un error en el servidor, por favor intente de nuevo';

            if (error.response.data['message'] === 'User already belongs to other organization') {
                msg = 'Usuario ya pertenece a otra entidad, por favor verifique';
            }
            setAlertModalType('danger');
        } finally {
            setAlertModalShow(true);
            setAlertModalMsg(msg);
            setIsLoading(false);
        }
    }

    const deleteUser = async (id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.delete('/users/' + id);

            getUsersByOrganization();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const activateUser = async (id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.put('/users/activate/' + id);

            getUsersByOrganization();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const addRemoveRol = async (user_id, role_id) => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.put('/users/add-remove-rol', { user_id, role_id, organization });

            getUsersByOrganization();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (organization) {
            getUsersByOrganization();
        } else {
            setUsers([]);
        }
    }, [organization]);

    // Initialize
    useEffect(() => {
        getOrganizations();
        getRoleList();
    }, []);

    return <>
        <Row>
            <Col md={12} xs={12}>
                <Row>
                    <Col md={9} xs={12}> <legend>Usuarios</legend></Col>
                </Row>
                <hr />
                <Form.Group className="mb-3">
                    <Form.Select value={organization} onChange={(e) => { setOrganization(e.target.value); }} >
                        <option key={'organizations' + 0} value="">Selecciona una entidad</option>
                        {organizations && organizations.map(item => (
                            <option key={'organization-' + item.id} value={item.id}>
                                {item.id}: {item.ext_id} {'-'} {item.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {organization ?
                    <Row>
                        <Col md={6} xs={0} >
                            <Alert>
                                Para agregar un nuevo usuario a tu entidad, es necesario que se registre previamente por su cuenta, luego podrá ser asociado a tu entidad aqui:
                                {' '}<i className='fa fa-arrow-right' />
                            </Alert>
                        </Col>
                        <Col md={6} xs={12} align="end">
                            <br></br>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="email"
                                    value={newUserEmail}
                                    onChange={(e) => { e.preventDefault(); setNewUserEmail(e.target.value); }}
                                    placeholder='Email usuario'
                                />
                                <Button variant="dark" disabled={!newUserEmail} id="button-addon1" onClick={() => addUser(newUserEmail)}>
                                    Asociar/Invitar usuario a entidad
                                </Button>
                                {' '}
                            </InputGroup>
                        </Col>
                    </Row>
                    : ''}
                <br />
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Email</th>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Roles</th>
                            <th>Centros de costo</th>
                            <th>Activo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(item => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.email}</td>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>
                                    {(item.user_role.length) ? item.user_role.map(rol => (
                                        <span key={'dep' + rol.id}>
                                            <Badge bg="secondary">
                                                {userId !== item.id ?
                                                    <Badge bg="danger">
                                                        <i className='fa fa-close' onClick={() => addRemoveRol(item.id, rol.role.id)} />
                                                    </Badge>
                                                    : ''}
                                                {' '}{rol.role.id}: {rol.role.description}
                                            </Badge>{' '}
                                        </span>
                                    )) : 'Ninguno'}
                                    <br></br>
                                    <br></br>
                                    {userId !== item.id ?
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => { addRemoveRol(item.id, e.target.value); e.target.value = '' }} >
                                            <option key={'roles' + 0} value="">Agregar/remover rol...</option>
                                            {rolesList && rolesList.map(item => (
                                                <option key={'rol-' + item.id} value={item.id}>
                                                    {item.id}: {item.description}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    : ''}
                                </td>
                                <td>
                                    {(item.user_department.length) ? item.user_department.map(department => (
                                        <span key={'dep' + department.id}>
                                            <Badge bg="secondary">{department.department.id}: {department.department.name}</Badge>{' '}
                                        </span>
                                    )) : ''}
                                </td>
                                <td>{item.deleted_at ? 'No' : 'Si'}</td>
                                <td>
                                    {userId !== item.id ? <>
                                        {item.deleted_at ?
                                            <Button variant="dark" onClick={(e) => activateUser(item.id)}>Activar</Button>
                                            :
                                            <Button variant="dark" onClick={(e) => deleteUser(item.id)}>Inactivar</Button>
                                        }
                                        {' '}
                                        {roles.includes('superadmin') ?
                                            <Button variant="outline-dark" onClick={(e) => autologin(item.id)}>Autologin</Button>
                                            : ''}
                                    </> : ''}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>

        </Row >
        <AlertModal
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm}
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            imgSrc={alertImgSrc}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        userId: state.userId,
        roles: state.roles,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);