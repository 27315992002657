import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import { Link } from 'react-router-dom';

function RecoverPassword(props) {
    const { setIsLoading } = props;
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const [email, setEmail] = useState('');

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');

    useEffect(() => {

    }, []);


    const recoverPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await axiosInstance.post('/password/recover', {
                email,

            })
            .then(function (response) {
                setAlertModalShow(true)
                setAlertModalMsg("Si estas registrado recibiras un email para que asignes una nueva contraseña");
                setAlertModalType(null);
                setIsLoading(false);
            })
        } catch (error) {
            let msg = "No pudimos conectar con el servidor, verifica el email ingresado e intenta de nuevo";

            setIsLoading(false);
            setAlertModalShow(true);
            setAlertModalType('danger');
            setAlertModalMsg(msg);
        }
    }

    return (
        <>
            <>
                <Row>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={12}>
                        <legend>Recuperar contraseña</legend>
                    </Col>
                </Row>
                <hr />
                <br />
                <Form onSubmit={recoverPassword}>
                    <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    required 
                                    placeholder="Te enviaremos un email para que asignes una nueva contraseña" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {message &&
                        <Row >
                            <Col xs={12} md={8}>fg
                                <Alert variant={messageType}>
                                    {message}vf
                                </Alert>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} md={8} align='end'>
                            <Button variant="dark" type="submit" disabled={!email}>
                                Recuperar mi contraseña
                            </Button>
                            {' '}
                            <Button as={Link} to="/login" variant="outline-dark" >Regresar</Button>
                        </Col>
                    </Row>
                </Form>
            </>

            <AlertModal
                show={alertModalShow} setShow={setAlertModalShow}
                msg={alertModalMsg} setMsg={setAlertModalMsg}
                variant={alertModalType}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);