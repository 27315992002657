import { Form, Button, Row, Col, Container, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';

function ProductTypes(props) {
    const { setIsLoading } = props;

    const [productTypes, setProductTypes] = useState([]);

    const [idEdit, setIdEdit] = useState('');
    const [productType, setProductType] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isCreation, setIsCreation] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertImgSrc, setAlertImgSrc] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(() => {
        setMessageType('warning');
        if (productType === '') {
            setMessage('Tipo de produto es obligatorio');
        } else {
            setMessage('');
        }
    }, [productType]);

    const setEditData = (row) => {
        setIdEdit(row.id);
        setProductType(row.name);
        setIsVisible(row.is_visible);
    }

    // Clear the form when is a creation
    useEffect(() => {
        if (isCreation) {
            setProductType('');
            setIsVisible(true);
            setIdEdit();
        }
    }, [isCreation]);

    const getProductTypes = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/product-types');
            console.log(response.data);

            setProductTypes(response.data);
            setIdEdit();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const saveProductType = async () => {
        setIsLoading(true);

        if (isCreation) {
            createProductType();
        } else {
            updateProductType();
        }
    }

    const createProductType = async () => {
        try {
            const response = await axiosInstance.post(`/product-types`, {
                name: productType,
                is_visible: isVisible
            });
            setIsCreation(false);
            getProductTypes();
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Tipo de produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const updateProductType = async () => {
        try {
            const response = await axiosInstance.put(`/product-types/${idEdit}`, {
                name: productType,
                is_visible: isVisible
            });
            setIdEdit();
            getProductTypes();
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Tipo de produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    // Get all product types
    useEffect(() => {
        if (!productTypes.length) {
            getProductTypes();
        }
    }, []);

    const deleteProductType = async (id, confirmed) => {
        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalType('');
            setAlertModalMsg('¿Esta seguro de eliminar el tipo de producto ' + id + '?');
            setAlertModalOnConfirm(()=>()=>{deleteProductType(id, true)});
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.delete(`/product-types/${id}`);

            setAlertModalShow(true);
            setAlertModalMsg('Tipo de producto ' + id + ' eliminado correctamente');
            setAlertModalOnClose(()=>()=>{setIdEdit(null); setIsCreation(false);});

            getProductTypes();
            console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');

            if (error.response.data.message.includes('foreign ')) {
                setAlertModalMsg("El tipo de producto no se puede elimar porque esta en uso");
            } else {
                setAlertModalMsg(error.response.data.message);
            }

            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return <>
        <Row>
            {(!idEdit && !isCreation) &&
                <Col md={12} xs={12}>
                    <Row>
                        <Col md={9} xs={12}> <legend>Tipos de Producto</legend></Col>
                        <Col md={3} xs={12} align="end">
                            <Button variant="dark" onClick={() => setIsCreation(true)}>
                                Crear Tipo de Producto
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <br />
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Tipo</th>
                                <th>Visible</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {productTypes.map(item => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.is_visible ? 'Si' : 'No'}</td>
                                    <td>
                                        <Button variant="dark" onClick={(e) => setEditData(item)}>Edit</Button>
                                        {' '}
                                        <Button variant="outline-dark" onClick={(e) => deleteProductType(item.id)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            }
            {(idEdit || isCreation) &&
                <>
                    <Row>
                        <Col md={8} xs={12}>

                            <legend>
                                {idEdit ?
                                    'Editar Tipo de Producto ' + idEdit + ': ' + productType
                                    :
                                    'Crear Tipo de Producto'
                                }
                            </legend>
                        </Col>
                        <Col md={4} xs={12}>
                            {idEdit &&
                                <Button variant="outline-dark" onClick={(e) => deleteProductType(idEdit)}>Eliminar Tipo de Producto</Button>
                            }
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>

                        <Form >
                            <Col md={4} xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo de Producto</Form.Label>
                                    <Form.Control type="text" placeholder="Tipo" value={productType} onChange={(e) => setProductType(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="mb-3" controlId="productTypeName">
                                    <Form.Check type="checkbox" label="Visible" checked={isVisible} onChange={(e) => setIsVisible(e.target.checked)} />
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                {message &&
                                    <Alert variant={messageType || 'warning'}>
                                        {message}
                                    </Alert>
                                }
                            </Col>
                            <Col md={4} xs={12}  align='end'>
                                <Button variant="dark" onClick={saveProductType} disabled={!!message}>
                                    Guardar
                                </Button>
                                {' '}
                                <Button variant="outline-dark" onClick={(e) => { setIdEdit(); setIsCreation(false); }}>
                                    Volver
                                </Button>
                            </Col>
                        </Form>
                    </Row>
                </>
            }
        </Row >
        <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg}  setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm}
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose} 
            imgSrc={alertImgSrc}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypes);