import { Form, Button, Row, Col, Container, Table, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import { connect } from 'react-redux';
import AlertModal from '../general/alertModal';
import { isVisible } from '@testing-library/user-event/dist/utils';

function FundUsers(props) {

    const { fundId, agreementId, department, setIsLoading } = props;

    const [userId, setUserId] = useState('');
    const [users, setUsers] = useState([]);
    const [fundUsers, setFundUsers] = useState([]);

    const [newUserFund, setNewUserFund] = useState([]);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    const asignUser = () => {
        setIsLoading(true);
        axiosInstance.post('users-funds',
            {
                user_id: newUserFund.id,
                fund_id: fundId
            })
            .then(response => {
                console.log(response.data);

                let item = { ...response.data, user: newUserFund };

                setFundUsers([...fundUsers, item]);
            })
            .catch(error => {
                setIsLoading(true);
                setAlertModalShow(true);
                setAlertModalType('danger');
                if (error.response.data.message.includes('Duplicate')) {
                    setAlertModalMsg('El usuario seleccionado ya esta asignado');
                } else {
                    setAlertModalMsg(error.response.data.message);
                }
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const deleteFundUser = (id) => {
        setIsLoading(true);
        axiosInstance.delete('users-funds/' + id)
            .then(response => {
                getFundUsers();
            })
            .catch(error => {
                console.log(error);
            }) 
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getUsersDepartment = () => {
        setIsLoading(true);
        axiosInstance.get('users/departments/' + department)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getFundUsers = () => {
        axiosInstance.get('users-funds/funds/' + fundId)
            .then(response => {
                setFundUsers(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        if (department) {
            getUsersDepartment();
        } else {
            setUsers([]);
        }
    }, [department]);

    useEffect(() => {
        if (fundUsers.length) {
            props.setHasUsers(true);
        } else {
            props.setHasUsers(false);
        }
    }, [fundUsers]);

    useEffect(() => {
        if (fundId) {
            getUsersDepartment();
            getFundUsers();
        }
    }, []);

    const handleUserChange = (id) => {
        if (!id) {
            return;
        }
        const selectedUser = users.find((user) => user.id === parseInt(id));
        setNewUserFund(selectedUser);
        setUserId(id);
    };

    return (<>
        {!fundId ?
            <Container>
                <Alert>
                    Luego de guardar el Fondo podrás asignar los usuarios aquí
                </Alert>
            </Container>
            :
            <>
                <Row>
                    <Col md={12} xs={12}>
                        <Container>
                            <b>Asignar Usuarios:</b><br></br><br></br>
                            <Form.Select value={userId} onChange={(e) => handleUserChange(e.target.value)}>
                                <option key={0} value='0'>
                                    --Seleccione un usuario--
                                </option>
                                {users && users.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.id}: {user.name} {user.email}
                                    </option>
                                ))}
                            </Form.Select>
                        </Container>
                    </Col>
                    <Col md={12} xs={12} align='end'>
                        <Button variant="outline-success" onClick={asignUser}>
                            <i className='fa fa-plus' /> Asignar
                        </Button>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <Table width={'100%'}>
                            <tbody>
                                {fundUsers.length  ?
                                    fundUsers.map(rel => (
                                        <tr key={rel.id} style={{display: !rel.user ? 'none' : ''}}>
                                            <td>{rel.user?.id}: {rel.user?.name} {rel.user?.email}</td>
                                            <td>
                                                <Button variant="outline-danger" onClick={() => deleteFundUser(rel.id)}>
                                                    <i className='fa fa-trash' />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={2}>
                                            <Alert>
                                                No  hay usuarios asignados
                                            </Alert>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        }
        <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            variant={alertModalType}
        />
    </>
    );
};

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(FundUsers);