import { Form, Button, Row, Col, Alert, Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import AlertModal from '../general/alertModal';
import DepartmentUsers from './departmentUsers';
import DepartmentsList from './departmentsList';

function Departments(props) {
    const { setIsLoading } = props;

    const [isCreation, setIsCreation] = useState(false);
    const [hasUsers, setHasUsers] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    // Fund fields
    const [idEdit, setIdEdit] = useState('');
    const [extId, setExtId] = useState('');
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(() => {
        setMessageType('warning');
        if (name == '') {
            return setMessage('Centro de Costos es obligatorio');
        } else {
            setMessage('');
        }
    }, [name]);


    // Clear the form when is a creation
    useEffect(() => {
        if (isCreation) {
            setIdEdit('');
            setName('');
            setExtId('');
            setIsActive(true);
        }
    }, [isCreation]);

    const getItemData = () => {
        return {
            ext_id: extId,
            name: name,
            is_active: isActive,
        }
    }

    const deleteItem = async (id, confirmed) => {
        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalMsg('¿Esta seguro de eliminar el centro de Costos ' + id + '?');
            setAlertModalOnConfirm(()=>()=>{deleteItem(id, true)});
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.delete(`/departments/${id}`);
                setAlertModalShow(true);
                setAlertModalMsg('Centro de Costos ' + id + ' eliminado correctamente');
                setAlertModalOnClose(()=>()=>{setIdEdit(false); setIsCreation(false)});
                console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');
            if (error.response.data.message.indexOf('Fund is used') > 0) {
                setAlertModalMsg('No es posible eliminar un centro de Costos en uso');
            } else if (error.response.data.message.indexOf('user_funds_fund_id_foreign') > 0) {
                setAlertModalMsg('No es posible eliminar un centro de Costos con usuarios asignados');
            } else {
                setAlertModalMsg(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const createItem = async () => {
        try {
            const response = await axiosInstance.post(`/departments`, getItemData());
            setIsCreation(false);
            //getList();
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Tipo de produto ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const saveItem = () => {
        if (isCreation) {
            createItem();
        } else {
            updateItem();
        }
    }

    const updateItem = async () => {
        try {
            const response = await axiosInstance.put(`/departments/${idEdit}`, getItemData());
            setIdEdit(0);
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Centro de Costos ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const setEditData = (row) => {
        setIdEdit(row.id);
        setName(row.name);
        setExtId(row.ext_id);
        setIsActive(row.is_active);
    }

    return <>
        {(!idEdit && !isCreation) &&
            <>
                <DepartmentsList isCreation={isCreation} setIsCreation={setIsCreation} setEditData={setEditData} />
            </>
        }
        {(idEdit || isCreation) &&
            <>
                <Row>
                    <Col md={8} xs={12}>

                        <legend>
                            {idEdit ?
                                'Modificar Centro de Costos ' + idEdit + ': ' + (extId || '')
                                :
                                'Crear Centro de Costos'
                            }
                        </legend>
                    </Col>
                    <Col md={4} xs={12} align={'end'}>
                        {idEdit &&
                            <Button variant="dark" disabled={hasUsers}
                                onClick={(e) => deleteItem(idEdit)}>
                                Eliminar Centro de Costos
                            </Button>
                        }
                    </Col>
                </Row>
                {(hasUsers) &&
                <Alert variant='warning'>
                    No se puede elimar un Centro de Costos con usuarios asignados
                </Alert>
                }
                <hr />
                <br />
                <Row>
                    <Col md={8} xs={12}>
                        <Form >
                            <Row>
                                <Col md={10} xs={12}>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Centro de Costos</Form.Label>
                                        <Form.Control type="text" placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} xs={12}>
                                    <Form.Group className="mb-3" controlId="visible">
                                        <Form.Check type="checkbox" label="Activo para pedidos nuevos"
                                             checked={isActive} onChange={(e) => setIsActive(e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {message &&
                                <Alert variant={messageType || 'warning'}>
                                    {message}
                                </Alert>
                            }
                            <Row align='end'>
                                <Col md={10} xs={12}>
                                    <Button variant="dark" onClick={saveItem} disabled={!!message}>
                                        Guardar
                                    </Button>
                                    {' '}
                                    <Button variant="outline-dark" onClick={(e) => { setIdEdit(false); setIsCreation(false); }}>
                                        Volver
                                    </Button>
                                </Col>
                                <br></br>
                                <br></br>
                            </Row>

                        </Form>
                    </Col>
                    <Col md={4} xs={12} as={Card}>
                        <DepartmentUsers fundId={idEdit} department={idEdit} setHasUsers={setHasUsers}/>
                    </Col>
                </Row>
            </>
        }
        <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);