import { connect } from 'react-redux';
import { default  as  OrderSearch } from './edit';

function Search() {
    return(<>
        <OrderSearch readonly={true}/>
    </>
    )
}

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
