import * as XLSX from 'xlsx';

const HOST = process.env.REACT_APP_HOST;
const DEFAULT_IMG = 'storage/images/system/product-default.png';
const LOGO_IMG_PATH = HOST + 'storage/images/logos/';

const getImage = (url)=>{
    if (url && url.includes('storage')) {
        return HOST + url
    } else if(url) {
        return url;
    } else {
        return HOST + DEFAULT_IMG;
    }
}

const currencyFormater  = (value, rounded=false)=>{
    if (rounded) {
        value = Math.round(value);
    }
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value)
}

const downloadExcel = (idTable, name = 'Abi', callback) => {
    const sheetName = 'Abi';
    const timestamp = new Date().getTime();
    const fileName = sheetName + ' ' + name + ' ' + timestamp + '.xlsx';
  
    setTimeout(()=>{
        const worksheet = XLSX.utils.table_to_sheet(document.getElementById(idTable));
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, fileName);

        if (typeof callback === 'function') {
            callback();
        }
    }, 50);
};

export { currencyFormater, getImage, downloadExcel, HOST,  DEFAULT_IMG, LOGO_IMG_PATH };