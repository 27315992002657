import { Form, Button, Row, Col, Alert, Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState, useMemo } from 'react';
import { setIsLoading } from '../general/actions';
import {currencyFormater} from '../general/utils'
import AlertModal from '../general/alertModal';
import FundUsers from './fundUsers';
import SelectAgreement from '../general/selectAgreement';
import FundsList from './fundsList';

function Funds(props) {
    const { setIsLoading } = props;

    const [productTypes, setProductTypes] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [isCreation, setIsCreation] = useState(false);
    const [hasUsers, setHasUsers] = useState(false);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    const getProductTypes = () => {
        setIsLoading(true);
        axiosInstance.get('/product-types')
            .then(res => {
                setProductTypes(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    const getDepartments = () => {
        setIsLoading(true);
        axiosInstance.get('/departments/agreements/' + agreement)
            .then(res => {
                setDepartments(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    // Fund fields
    const [idEdit, setIdEdit]           = useState('');
    const [agreement, setAgreement]     = useState('');
    const [extId, setExtId]             = useState('');
    const [name, setName]               = useState('');
    const [description, setDescription] = useState('');
    const [productType, setProductType] = useState('');
    const [department, setDepartment]   = useState('');
    const [isActive, setIsActive]       = useState(true);
    const [amount, setAmount]           = useState(0);
    const [spent, setSpent]             = useState(0);
    const available = useMemo(() => {
        return amount - spent;
    }, [amount, spent]);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(() => {
        setMessageType('warning');
        if (extId == '') {
            return setMessage('Ext Id es obligatorio, ingresa un número único para identificar tu fondo');
        } if (name == '') {
            return setMessage('Nombre es obligatorio');
        } else if (!agreement) {
            return setMessage('Contrato es obligatorio');
        } else if (isNaN(amount) || amount <= 0) {
            return setMessage('Valor inicial inválido');
        } else if (!department) {
            return setMessage('Centro de costo inválido');
        } else if (amount < spent) {
            return setMessage('Valor inicial no puede ser menos que el consumo');
        } else {
            setMessage('');
        }
    }, [extId, name, agreement, department, amount]);


    // Clear the form when is a creation
    useEffect(() => {
        if (isCreation) {
            setIdEdit('');
            setName('');
            //setAgreement('');
            setDescription('');
            setExtId('');
            setIsActive(true);
            setAmount(0);
            setSpent(0);
            setProductType('');
            setDepartment('');
        }
    }, [isCreation]);

    const getItemData = () => {
        return {
            ext_id         : extId,
            name           : name,
            agreement_id   : agreement,
            description    : description,
            amount         : amount,
            is_active      : isActive,
            product_type_id: productType,
            department_id  : department,
        }
    }

    const deleteItem = async (id, confirmed) => {
        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalMsg('¿Esta seguro de eliminar el fondo ' + id + '?');
            setAlertModalOnConfirm(()=>()=>{deleteItem(id, true)});
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.delete(`/funds/${id}`);
                setAlertModalShow(true);
                setAlertModalMsg('Fondo ' + id + ' eliminado correctamente');
                setAlertModalOnClose(()=>()=>{setIdEdit(false); setIsCreation(false)});
                console.log(response.data);
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalType('danger');
            if (error.response.data.message.indexOf('Fund is used') > 0) {
                setAlertModalMsg('No es posible eliminar un fondo con consumo');
            } else if (error.response.data.message.indexOf('user_funds_fund_id_foreign') > 0) {
                setAlertModalMsg('No es posible eliminar un fondo con usuarios asignados');
            } else {
                setAlertModalMsg(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const createItem = async () => {
        try {
            const response = await axiosInstance.post(`/funds`, getItemData());
            setIsCreation(false);
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Fondo ya existe, el Ext id debe ser único por contrato');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const saveItem = () => {

        setIsLoading(true);

        let baseUrl = isCreation ?  '/funds' : '/funds/' + idEdit;

        axiosInstance.get(baseUrl + '/agreements/' + agreement + '/ext-id/' + extId)
        .then(res => {
            setIsLoading(false);
            if (res.data.length) {
                setAlertModalShow(true);
                setAlertModalType('danger');
                setAlertModalMsg('Ext id ya existe en otro fondo');
            } else {

                if (isCreation) {
                    createItem();
                } else {
                    updateItem();
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    }

    const updateItem = async () => {
        try {
            const response = await axiosInstance.put(`/funds/${idEdit}`, getItemData());
            setIdEdit(0);
            console.log(response.data);
        } catch (error) {
            setMessageType('danger');
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setMessage('Ext Id ya existe');
            } else {
                setMessage(error.message);
            }
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const setEditData = (row) => {
        setIdEdit(row.id);
        setName(row.name);
        setAgreement(row.agreement_id);
        setDescription(row.description);
        setExtId(row.ext_id);
        setIsActive(row.is_active);
        setAmount(parseFloat(isNaN(row.amount) ? 0 : row.amount));
        setSpent(parseFloat(row.spent));
        setProductType(row.product_type_id);
        setDepartment(row.department && row.department.id ? row.department.id : '');
    }

    useEffect(() => {
        if (agreement) {
            getDepartments();
            getProductTypes();

        }
    }, [agreement]);

    return <>
        {(!idEdit && !isCreation) &&
            <>
                <FundsList 
                    isCreation={isCreation}
                    setIsCreation={setIsCreation}
                    setEditData={setEditData}
                    setAgreement={setAgreement}
                    agreement={agreement}
                />
            </>
        }
        {(idEdit || isCreation) &&
            <>
                <Row>
                    <Col md={8} xs={12}>

                        <legend>
                            {idEdit ?
                                'Modificar Fondo ' + idEdit + ': ' + extId
                                :
                                'Crear Fondo'
                            }
                        </legend>
                    </Col>
                    <Col md={4} xs={12} align={'end'}>
                        {idEdit &&
                            <Button variant="dark" disabled={spent > 0 || hasUsers}
                                onClick={(e) => deleteItem(idEdit)}>
                                Eliminar Fondo
                            </Button>
                        }
                    </Col>
                </Row>
                {(spent > 0 || hasUsers) &&
                <Alert variant='warning'>
                    No se puede elimar un fondo con usuarios asignados y consumos
                </Alert>
                }
                <hr />
                <br />
                <Row>
                    <Col md={8} xs={12}>
                        <Form >
                            <Row>
                                <Col md={12} xs={12}>
                                <SelectAgreement agreement={agreement} setAgreement={setAgreement} disabled={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ext Id</Form.Label>
                                        <Form.Control type="text" placeholder="Id externo del fondo" value={extId} onChange={(e) => setExtId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} xs={12}>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text" placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tipo de Producto</Form.Label>
                                        <Form.Select value={productType} onChange={(e) => setProductType(e.target.value)} disabled>
                                            <option key={'productType' + 0} value='0'>
                                                Todos
                                            </option>
                                            {productTypes.map(productType => (
                                                <option key={productType.id} value={productType.id}>
                                                    {productType.id}: {productType.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Centro de costos</Form.Label>
                                        <Form.Select value={department} onChange={(e) => setDepartment(e.target.value)} disabled={spent > 0 && hasUsers}>
                                            <option key={0} value={0}>
                                                   {!agreement ? '--Selecciona un contrato para cargar los centros de costo--': '--Selecciona un centro de costos--'} 
                                            </option>
                                            {departments.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.id}: {item.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Valor inicial: {currencyFormater(amount)}</Form.Label>
                                        <Form.Control onChange={(e) => setAmount(e.target.value)} type="number" placeholder="" value={amount} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Consumo</Form.Label>
                                        <Form.Control disabled type="text" placeholder="" value={currencyFormater(spent)}  />
                                    </Form.Group>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Disponible</Form.Label>
                                        <Form.Control disabled type="text" placeholder="" value={currencyFormater(available)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control as="textarea" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <Form.Group className="mb-3" controlId="visible">
                                        <Form.Check type="checkbox" label="Activo para pedidos nuevos" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {message &&
                                <Alert variant={messageType || 'warning'}>
                                    {message}
                                </Alert>
                            }
                            <Row align='end'>
                                <Col md={12} xs={12}>
                                    <Button variant="dark" onClick={saveItem} disabled={!!message}>
                                        Guardar
                                    </Button>
                                    {' '}
                                    <Button variant="outline-dark" onClick={(e) => { setIdEdit(false); setIsCreation(false); }}>
                                        Volver
                                    </Button>
                                </Col>
                                <br></br>
                                <br></br>
                            </Row>

                        </Form>
                    </Col>
                    <Col md={4} xs={12} as={Card}>
                        <FundUsers fundId={idEdit} agreementId={agreement} department={department} setHasUsers={setHasUsers}/>
                    </Col>
                </Row>
            </>
        }
         <AlertModal 
            show={alertModalShow} setShow={setAlertModalShow}
            msg={alertModalMsg} setMsg={setAlertModalMsg}
            onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
            onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
            variant={alertModalType}
        />
    </>;
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(Funds);